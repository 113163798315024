import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IDeclaration } from '../../../core/models/declaration.interface';
import { IDeclarationMessage } from '../../../core/models/declarationMessage.interface';
import { IMessageBanner } from '../../../../shared/message-banner/message-banner.interface';
import { DeclarationState } from '../../../core/models/declarationState.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { IProtection } from '../../../core/models/protection.interface';
import { DeclarationService } from '../../../core/services/declaration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IMessageStreamItem } from '../../../core/models/messageStreamItem.interface';
import { IDocument } from '../../../core/models/document.interface';
import { IDocumentDisplayData } from '../../../core/models/document-display-data.interface';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { formatDate, formatNumber, getCurrencySymbol, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { DeclarationShopFeedback } from '../../../core/models/declarationShopFeedback.enum';
import { UserService } from '../../../../core/services/user.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';

@Component({
  selector: 'app-declarations',
  templateUrl: './declarations-details.component.html',
  styleUrls: ['./declarations-details.component.scss'],
  animations: [
    trigger('showForm', [
      state('show', style({
        height: '*',
        paddingTop: '15px',
        borderBottom: '1px solid #e5e5e5'
      })),
      state('hide', style({
        height: '0'
      })),
      transition('* => show', animate('200ms ease-out'))
    ])
  ]
})
export class DeclarationsDetailsComponent implements OnInit {
  //#region Private fields
  private readonly _route: ActivatedRoute;
  private readonly _declarationService: DeclarationService;
  private readonly _userService: UserService;
  private readonly _translateService: TranslateService;
  private readonly _router: Router;
  private _documents: Array<IDocument>;
  //#endregion

  //#region Properties
  @ViewChild('container') public containerElement: ElementRef;

  @ViewChild(ErrorModalComponent) errorModal: ErrorModalComponent;

  private _declaration: IDeclaration;
  public get declaration(): IDeclaration {
    return this._declaration;
  }

  private _protectionNumber: string;
  public get protectionNumber(): string {
    return this._protectionNumber;
  }

  private _protection: IProtection;
  public get protection(): IProtection {
    return this._protection;
  }

  private _protectionUuid: string;
  public get protectionUuid(): string {
    return this._protectionUuid;
  }

  private _message = '';
  public get message(): string {
    return this._message;
  }
  public set message(v: string) {
    this._message = v;
  }

  private _messageBanner: IMessageBanner;
  public get messageBanner(): IMessageBanner {
    return this._messageBanner;
  }

  private _showForm = 'hide';
  public get showForm(): string {
    return this._showForm;
  }

  private _messageStreamItems: IMessageStreamItem[];
  public get messageStreamItems(): IMessageStreamItem[] {
    return this._messageStreamItems;
  }

  private _willRefund: number;
  public get willRefund(): number {
    return this._willRefund;
  }
  public set willRefund(v: number) {
    this._willRefund = v;
  }

  private _locale = 'de';
  public get locale(): string {
    return this._locale;
  }

  private _textMask = createNumberMask({
    prefix: '',
    decimalSymbol: ',',
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalLimit: 2
  });
  public get textMask(): any {
    return this._textMask;
  }

  private _refundAmount;
  public get refundAmount(): string {
    return this._refundAmount;
  }
  public set refundAmount(v: string) {
    this._refundAmount = v;
  }

  private _currencySymbol: string;
  public get currencySymbol(): string {
    return this._currencySymbol;
  }

  private _uploadDocuments: Array<IDocumentDisplayData> = [];
  public get uploadDocuments(): Array<IDocumentDisplayData> {
    return this._uploadDocuments;
  }

  private _isLoading = false;
  public get isLoading(): boolean {
    return this._isLoading;
  }
  //#endregion

  constructor(route: ActivatedRoute,
              declarationService: DeclarationService,
              userService: UserService,
              translateService: TranslateService,
              router: Router) {
    this._route = route;
    this._declarationService = declarationService;
    this._userService = userService;
    this._translateService = translateService;
    this._router = router;
  }

  ngOnInit() {
    const css = '#shopSelectorShopDiv { display: none; }';
    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.appendChild(document.createTextNode(css));
    if (this.containerElement) {
      this.containerElement.nativeElement.appendChild(styleElement);
    }

    this._isLoading = true;
    this._route.params.subscribe(async params => {
      try {
        this._protectionNumber = params.protectionNumber;
        this._protectionUuid = params.protectionUuid;

        this._protection = await this._declarationService.getProtection(this._protectionUuid);

        this._locale = this._translateService.currentLang;
        this._refundAmount = formatNumber(this._protection.orderAmount, this._locale, '1.2-2');
        this._textMask = createNumberMask({
          prefix: '',
          decimalSymbol: getLocaleNumberSymbol(this._locale, NumberSymbol.CurrencyDecimal),
          thousandsSeparatorSymbol: getLocaleNumberSymbol(this._locale, NumberSymbol.CurrencyGroup),
          allowDecimal: true,
          decimalLimit: 2
        });

        this._currencySymbol = getCurrencySymbol(this._protection.orderCurrency, 'narrow', this._locale);

        if (params.declarationUuid) {
          this._declaration = await this._declarationService.getDeclaration(params.declarationUuid);
        }

        if (this._declaration && this._declaration.state !== DeclarationState.CANCELED) {

          if (!this._declaration.currency) {
            this._declaration.currency = this._protection.orderCurrency;
          }

          if (!this._declaration.shopAssignee || this._declaration.shopAssignee !== this._userService.shop_uuid.value) {
            this._declaration = await this._declarationService.claimDeclaration(params.declarationUuid);
          }

          this._documents = await this._declarationService.getDocuments(params.declarationUuid);

          let messageBannerText = 'DECLARATION_MESSAGE_BANNER.TS_CHECK_TEXT';
          if (this._declaration.state === DeclarationState.TS_CHECK &&
            this._declaration.refundReceived && this._declaration.refundReceived === 'NOT_RECEIVED') {
            messageBannerText = 'DECLARATION_MESSAGE_BANNER.TS_CHECK_CONSUMER_FEEDBACK_TEXT';
          } else if (this._declaration.state === DeclarationState.TS_CHECK &&
            this._declaration.refundReceived && this._declaration.refundReceived === 'NOT_ACCEPTED') {
            messageBannerText = 'DECLARATION_MESSAGE_BANNER.TS_CHECK_CONSUMER_FEEDBACK_NOT_ACCEPTED_TEXT';
          } else if ((this._declaration.state === DeclarationState.TS_CHECK ||
            this._declaration.state === DeclarationState.CONSUMER_CONFIRM) &&
            !this._declaration.refundReceived &&
            !this._declaration.feedbackShop &&
            this._declaration.oldState !== 'SHOP_INFO_PROVIDED') {
            messageBannerText = 'DECLARATION_MESSAGE_BANNER.TS_CHECK_SHOP_DID_NOT_ANSWER_TEXT';
          }

          if (this._declaration.state === DeclarationState.TS_CHECK ||
            this._declaration.state === DeclarationState.CONSUMER_CONFIRM ||
            this._declaration.state === DeclarationState.FEEDBACK_NEEDED_CONSUMER ||
            this._declaration.state === DeclarationState.WAITING_FOR_BANKACCOUNT_INFO) {
            const headline = await new Promise<string>(resolve => {
              this._translateService.get('DECLARATION_MESSAGE_BANNER.TS_CHECK_HEADLINE').subscribe(
                next => {
                  resolve(next as string);
                }
              );
            });
            const text = await new Promise<string>(resolve => {
              this._translateService.get(messageBannerText).subscribe(
                next => {
                  resolve(next as string);
                }
              );
            });
            this._messageBanner = {
              headline: headline,
              message: text,
              symbol: 'fa-info-circle',
              type: 'orange'
            };
          }

          let messages = await this._declarationService.getMessagesForDeclaration(params.declarationUuid);

          messages = await this._parseMessages(messages);
          this._messageStreamItems = await this._buildMessageStream(messages);
          this._uploadDocuments = this._documents.filter(x => x.status === 'UNCOMMITTED' &&
            (!x.attachedToMessages || x.attachedToMessages.length === 0)).map(document => {
            return {
              document
            } as IDocumentDisplayData;
          });
          this._isLoading = false;
        } else {
          let translateString = 'MESSAGE_STREAM.ACTIVE_PROTECTION';
          if (this._protection.protectionState === 'CLOSED' || new Date(this._protection.expirationDate).getTime() < new Date().getTime()
           || this._declaration && this.declaration.state === 'CANCELED') {
            translateString = 'MESSAGE_STREAM.PROTECTION_CLOSED';
          }

          this._translateService.get(translateString,
            {date: formatDate(new Date(this._protection.expirationDate).getTime(), 'shortDate', this._locale)})
            .subscribe(next => {
              this._messageStreamItems = [{
                message: next,
                translateMessage: false,
                sender: 'NONE',
                initiallyOpen: true,
                showBorder: false
              }];
              this._isLoading = false;
            });
        }
      } catch {}
    });
  }

  async save() {
    let saved: boolean;
    this._isLoading = true;
    try {
      const savedMessage = await this._declarationService.createMessageForDeclaration(this._declaration.declarationUuid, this._message);
      if (this._uploadDocuments.length > 0) {
        const documentUuids = this._uploadDocuments.map(x => x.document.uuid);
        await this._declarationService.attachDocumentToMessage(this._declaration.declarationUuid, savedMessage.uuid, documentUuids);
      }

      if (this._declaration.state === DeclarationState.DECLARED) {
        let feedback: DeclarationShopFeedback = DeclarationShopFeedback.NON_REFUND;
        sessionStorage.setItem('message_type', JSON.stringify({type: 'SAVED', protectionNumber: this._protectionNumber}));
        let amountRefund = 0.0;
        if (this.willRefund === 1) {
          if (this._parseFloat(this._refundAmount) < this._protection.orderAmount) {
            feedback = DeclarationShopFeedback.PARTIAL_REFUND;
            amountRefund = this._parseFloat(this._refundAmount);
          } else {
            feedback = DeclarationShopFeedback.REFUND;
            amountRefund = this._protection.orderAmount;
          }
          sessionStorage.setItem('message_type', JSON.stringify({type: 'SAVED', protectionNumber: this._protectionNumber}));
        } else if (this.willRefund === 2) {
          if (this._parseFloat(this._refundAmount) < this._protection.orderAmount) {
            feedback = DeclarationShopFeedback.PARTIAL_REFUND_LATER;
            amountRefund = this._parseFloat(this._refundAmount);
          } else {
            feedback = DeclarationShopFeedback.REFUND_LATER;
            amountRefund = this._protection.orderAmount;
          }
          sessionStorage.setItem('message_type', JSON.stringify({type: 'SAVED', protectionNumber: this._protectionNumber}));
        }
        saved = await this._declarationService.updateDeclaration(this._declaration.declarationUuid, feedback, amountRefund);
      } else {
        saved = true;
      }
    } catch (e) {
      this.errorModal.openModal();
      saved = false;
      this._isLoading = false;
    }
    if (saved) {
      let commited: boolean;
      try {
        commited = this._declaration.state === DeclarationState.DECLARED ?
          await this._declarationService.commitFeedback(this._declaration.declarationUuid) :
          await this._declarationService.commitAdditionalInformation(this._declaration.declarationUuid);
      } catch (e) {
        this.errorModal.openModal();
        commited = false;
        this._isLoading = false;
      }

      if (commited) {
        this._isLoading = false;
        this._router.navigate(['']);
      }
    }
  }

  documentsChanged(documents: IDocumentDisplayData[]) {
    this._uploadDocuments = documents;
  }

  triggerFormOpen() {
    this._showForm = 'show';
  }

  private _parseFloat(value: string): number {
    const decimal = getLocaleNumberSymbol(this._translateService.currentLang, NumberSymbol.CurrencyDecimal);
    const group = getLocaleNumberSymbol(this._translateService.currentLang, NumberSymbol.CurrencyGroup);

    const parsed = value.replace(group, '').replace(decimal, '.');
    return parseFloat(parsed);
  }

  private async _parseMessages(messages: Array<IDeclarationMessage>): Promise<Array<IDeclarationMessage>> {
    return await Promise.all(messages.map(async message => {
      if (message.message === null || message.message === undefined) {
        message.message = '';
      }
      const translations = message.message.match(/__translate__[A-Z._]+/gm);
      if (translations) {
        const translationKeys = translations.map(key => key.substr(13));
        const translated = await new Promise(resolve => {
          this._translateService.get(translationKeys).subscribe(values => {
            resolve(translationKeys.map(key => values[key]));
          });
        }) as string[];
        translated.forEach((key, index) => {
          message.message = message.message.replace(translations[index], key);
        });
      }
      return message;
    }));
  }

  private async _buildMessageStream(messages: IDeclarationMessage[]): Promise<IMessageStreamItem[]> {
    const messageItems = new Array<IMessageStreamItem>();

    if (messages.length === 0 && (this._declaration.state === DeclarationState.CLOSED || this._declaration.state === 'CANCELED')) {
      const headline = await new Promise<string>(resolve => {
        this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED').subscribe(
          translation => {
            resolve(translation as string);
          }
        );
      });

      messageItems.push({
        sender: 'NONE',
        headline,
        initiallyOpen: true,
        showBorder: false,
        declarationUuid: this._declaration.declarationUuid
      });

      return messageItems;
    }

    if (this._declaration.migrated && messages[0].senderRole !== 'CONSUMER') {
      messageItems.push({
        sender: 'CONSUMER',
        userEmail: this._protection.consumerProtectionEmail,
        initiallyOpen: true,
        showBorder: false,
        declarationUuid: this._declaration.declarationUuid
      });
    }

    for (const declarationMessage of messages) {
      messageItems.push({
        creationDate: new Date(declarationMessage.commitDate !== null ? declarationMessage.commitDate : declarationMessage.creationDate),
        message: declarationMessage.message,
        messageUuid: declarationMessage.uuid,
        translateMessage: false,
        sender: declarationMessage.senderRole,
        initiallyOpen: false,
        deliveryDate: this._declaration.actualDeliveryDate,
        showBorder: true,
        declarationUuid: this._declaration.declarationUuid,
        userEmail: declarationMessage.senderRole === 'CONSUMER' ? this._protection.consumerProtectionEmail : null
      });
    }


    let messageHeadline = await new Promise<string>(resolve => {
      this._translateService.get('MESSAGE_STREAM.NO_DELIVERY_HEADLINE').subscribe(
        translation => {
          resolve(translation as string);
        }
      );
    });

    if (this._declaration.reason === 'RNR') {
      messageHeadline = await new Promise<string>(resolve => {
        this._translateService.get('MESSAGE_STREAM.NO_REFUND_HEADLINE').subscribe(
          translation => {
            resolve(translation as string);
          }
        );
      });
      messageItems[0].revocationDate = this._declaration.cancellationDate;
    }
    messageItems[0].headline = messageHeadline;

    if (this._declaration.feedbackShop) {
      if (this._declaration.migrated && (messages.length === 0 || messages[0].senderRole !== 'SHOP')) {
        messageItems.splice(1, 0, {
          sender: 'SHOP',
          initiallyOpen: true,
          showBorder: true,
          declarationUuid: this._declaration.declarationUuid,
        });
      }

      messageHeadline = await new Promise<string>(resolve => {
        this._translateService.get('MESSAGE_STREAM.SHOP_WILL_REFUND').subscribe(
          translation => {
            resolve(translation as string);
          }
        );
      });

      if (this._declaration.feedbackShop === DeclarationShopFeedback.NON_REFUND) {
        messageHeadline = await new Promise<string>(resolve => {
          this._translateService.get('MESSAGE_STREAM.SHOP_WONT_REFUND').subscribe(
            translation => {
              resolve(translation as string);
            }
          );
        });
      } else if (this._declaration.feedbackShop === DeclarationShopFeedback.REFUND_LATER) {
        messageHeadline = await new Promise<string>(resolve => {
          this._translateService.get('MESSAGE_STREAM.SHOP_WILL_REFUND_LATER').subscribe(
            translation => {
              resolve(translation as string);
            }
          );
        });
      } else if (this._declaration.feedbackShop === DeclarationShopFeedback.PARTIAL_REFUND_LATER) {
        const locale = this._translateService.currentLang;
        const formattedAmount = formatNumber(this._declaration.amountRefund, locale, '1.2-2');
        const currencySymbol = getCurrencySymbol(this._declaration.currency, 'narrow', locale);
        const refundAmount = `${formattedAmount} ${currencySymbol}`;
        messageHeadline = await new Promise<string>(resolve => {
          this._translateService.get('MESSAGE_STREAM.SHOP_WILL_PARTIALLY_REFUND_LATER', {refund: refundAmount}).subscribe(
            translation => {
              resolve(translation as string);
            }
          );
        });
      } else if (this._declaration.feedbackShop === DeclarationShopFeedback.PARTIAL_REFUND) {
        const locale = this._translateService.currentLang;
        const formattedAmount = formatNumber(this._declaration.amountRefund, locale, '1.2-2');
        const currencySymbol = getCurrencySymbol(this._declaration.currency, 'narrow', locale);
        const refundAmount = `${formattedAmount} ${currencySymbol}`;
        messageHeadline = await new Promise<string>(resolve => {
          this._translateService.get('MESSAGE_STREAM.SHOP_WILL_PARTIALLY_REFUND', {refund: refundAmount}).subscribe(
            translation => {
              resolve(translation as string);
            }
          );
        });
      }

      messageItems[1].headline = messageHeadline;
    } else if (!this._declaration.feedbackShop &&
      this._declaration.oldState !== 'SHOP_INFO_PROVIDED' &&
      this._declaration.state !== DeclarationState.DECLARED &&
      messages.filter(m => m.senderRole === 'EMPLOYEE').length > 0) {
      const headline = await new Promise<string>(resolve => {
        this._translateService.get('MESSAGE_STREAM.SHOP_DID_NOT_ANSWER').subscribe(
          translation => {
            resolve(translation as string);
          }
        );
      });

      messageItems.splice(1, 0, {
        commitCycle: this._declaration.commitCycle,
        message: 'MESSAGE_STREAM.SEND_TO_TS_FOR_CHECK',
        translateMessage: true,
        sender: 'EMPLOYEE',
        headline,
        initiallyOpen: messages.length === 0 && !this._declaration.decision,
        showBorder: true,
        userEmail: this._protection.consumerProtectionEmail,
        declarationUuid: this._declaration.declarationUuid
      });
    }

    if (this._declaration.refundReceived && this._declaration.refundReceived === 'NOT_ACCEPTED') {
      const headline = await new Promise<string>(resolve => {
        this._translateService.get('MESSAGE_STREAM.NOT_ACCEPTED').subscribe(
          translation => {
            resolve(translation as string);
          }
        );
      });
      messageItems[2].headline = headline;
    } else if (this._declaration.refundReceived && this._declaration.refundReceived === 'NOT_RECEIVED') {
      const headline = await new Promise<string>(resolve => {
        this._translateService.get('MESSAGE_STREAM.REFUND_NOT_RECEIVED').subscribe(
          translation => {
            resolve(translation as string);
          }
        );
      });
      messageItems.splice(2, 0, {
        creationDate: new Date(this._declaration.changedOn),
        commitCycle: this._declaration.commitCycle,
        message: '',
        translateMessage: false,
        sender: 'CONSUMER',
        headline,
        initiallyOpen: true,
        showBorder: true,
        userEmail: this._protection.consumerProtectionEmail,
        declarationUuid: this._declaration.declarationUuid
      });
    }

    if (this._declaration.state === DeclarationState.CLOSED && !this._declaration.decision) {
      let headline: string;
      let message: string;

      if (this._declaration.refundReceived) {
        headline = await new Promise<string>(resolve => {
          this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED').subscribe(
            translation => {
              resolve(translation as string);
            }
          );
        });

        if (this._declaration.refundReceived === 'RECEIVED') {
          message = await new Promise<string>(resolve => {
            this._translateService.get('MESSAGE_STREAM.RECEIVED').subscribe(
              translation => {
                resolve(translation as string);
              }
            );
          });
        }
      } else {
        headline = await new Promise<string>(resolve => {
          this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED_AUTOMATICALLY').subscribe(
            translation => {
              resolve(translation as string);
            }
          );
        });

        if (this._declaration.feedbackShop === 'REFUND') {
          message = await new Promise<string>(resolve => {
            this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED_NO_ANSWER').subscribe(
              translation => {
                resolve(translation as string);
              }
            );
          });
        } else {
          const locale = this._translateService.currentLang;
          const formattedAmount = formatNumber(this._declaration.amountRefund, locale, '1.2-2');
          const currencySymbol = getCurrencySymbol(this._declaration.currency, 'narrow', locale);
          const refundAmount = `${formattedAmount} ${currencySymbol}`;

          message = await new Promise<string>(resolve => {
            this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED_NO_ANSWER_PARTIAL', {refundAmount}).subscribe(
              translation => {
                resolve(translation as string);
              }
            );
          });
        }
      }

      headline = await new Promise<string>(resolve => {
        this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED').subscribe(
          translation => {
            resolve(translation as string);
          }
        );
      });

      messageItems.push({
        creationDate: new Date(this._declaration.changedOn),
        commitCycle: this._declaration.commitCycle,
        sender: this._declaration.refundReceived ? 'CONSUMER' : 'EMPLOYEE',
        headline,
        initiallyOpen: true,
        showBorder: true,
        userEmail: this._protection.consumerProtectionEmail,
        declarationUuid: this._declaration.declarationUuid
      });
    } else if ((this._declaration.state === DeclarationState.CLOSED || this._declaration.state === DeclarationState.DECISION_MADE) &&
      this._declaration.decision) {
      let headline;
      let message = this._declaration.decision.reason;

      if (!this._declaration.decision.reasonable && !this._declaration.decision.goodwill) {

        /* ***************************************** */
        /* Protection closed with decision no refund */
        /* ***************************************** */

        headline = await new Promise<string>(resolve => {
          this._translateService.get('MESSAGE_STREAM.TS_DECISION_NO_REFUND').subscribe(
            translation => {
              resolve(translation as string);
            }
          );
        });
      } else if (this._declaration.decision.reasonable && !this._declaration.decision.goodwill) {

        /* ***************************************** */
        /* Protection closed with decision refund */
        /* ***************************************** */
        const locale = this._translateService.currentLang;
        const formattedAmount = formatNumber(this._declaration.decision.amountRefund, locale, '1.2-2');
        const currencySymbol = getCurrencySymbol(this._declaration.currency, 'narrow', locale);
        const refundAmount = `${formattedAmount} ${currencySymbol}`;

        headline = await new Promise<string>(resolve => {
          this._translateService.get('MESSAGE_STREAM.TS_DECISION_REFUND', {amount: refundAmount}).subscribe(
            translation => {
              resolve(translation as string);
            }
          );
        });
      } else if (!this._declaration.decision.reasonable && this._declaration.decision.goodwill) {

        /* *********************************************** */
        /* Protection closed with decision goodwill refund */
        /* *********************************************** */
        const locale = this._translateService.currentLang;
        const formattedAmount = formatNumber(this._declaration.decision.amountRefund, locale, '1.2-2');
        const currencySymbol = getCurrencySymbol(this._declaration.currency, 'narrow', locale);
        const refundAmount = `${formattedAmount} ${currencySymbol}`;
        message = '';

        headline = await new Promise<string>(resolve => {
          this._translateService.get('MESSAGE_STREAM.TS_DECISION_NO_REFUND', {amount: refundAmount}).subscribe(
            translation => {
              resolve(translation as string);
            }
          );
        });
      }

      messageItems.push({
        creationDate: new Date(this._declaration.changedOn),
        headline,
        message,
        translateMessage: false,
        sender: 'EMPLOYEE',
        initiallyOpen: true,
        showBorder: true,
        declarationUuid: this._declaration.declarationUuid
      });

      if (this._declaration.state === DeclarationState.CLOSED) {
        headline = await new Promise<string>(resolve => {
          this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED').subscribe(
            translation => {
              resolve(translation as string);
            }
          );
        });

        messageItems.push({
          creationDate: new Date(this._declaration.changedOn),
          commitCycle: this._declaration.commitCycle,
          sender: this._declaration.refundReceived ? 'CONSUMER' : 'EMPLOYEE',
          headline,
          initiallyOpen: true,
          showBorder: true,
          userEmail: this._protection.consumerProtectionEmail,
          declarationUuid: this._declaration.declarationUuid
        });
      }
    }

    if (this._declaration.state === DeclarationState.DECLARED ||
      this._declaration.state === DeclarationState.FEEDBACK_NEEDED_SHOP ||
      this._declaration.state === DeclarationState.FEEDBACK_NEEDED_CONSUMER_AND_SHOP) {
      messageItems[messageItems.length - 1].showButton = true;
    }

    messageItems[0].showBorder = false;
    messageItems[messageItems.length - 1].initiallyOpen = true;

    return this._addDocuments(messageItems.map(x => {
      if (!x.headline) {
        x.initiallyOpen = true;
      }
      return x;
    })).reverse();
  }

  private _addDocuments(messageItems: Array<IMessageStreamItem>): Array<IMessageStreamItem> {
    for (const message of messageItems) {
      if (message.messageUuid) {
        const filteredDocuments = this._documents.filter(document => document.attachedToMessages.includes(message.messageUuid));
        if (filteredDocuments.length > 0) {
          message.documents = filteredDocuments.map(document => {
            return { document } as IDocumentDisplayData;
          });
        }
      }
    }
    return messageItems;
  }
}
