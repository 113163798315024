"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = {
    production: true,
    shopOAuth: 9,
    protections: {
        declarationAPI: 'https://cns-integr.trustedshops.com/cns-declaration-service/v1/shop-users/',
        ordersApi: 'https://cns-integr.trustedshops.com/cns-orders-service/v1/shop-users/'
    },
    keycloak: {
        realm: 'business-INTEGR',
        clientId: 'b2b-sso-cns',
        host: 'https://login-integr.etrusted.com/auth',
        protectedUrls: [
            /^http[s]?\:\/\/cns-integr\.trustedshops\.com\/.*$/
        ]
    }
};
