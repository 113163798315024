"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var angular_jwt_1 = require("@auth0/angular-jwt");
var user_service_1 = require("./user.service");
var KeycloakService = /** @class */ (function () {
    function KeycloakService(userService, keycloak, window) {
        this._auth = {};
        this._userService = userService;
        this._keycloak = keycloak;
        this._window = window;
    }
    KeycloakService.prototype.init = function (args) {
        var _this = this;
        var keycloakAuth = this._keycloak(args.initArgs);
        return new Promise(function (resolve, reject) {
            keycloakAuth.init({ onLoad: 'login-required', checkLoginIframe: false })
                .success(function () {
                _this._auth = keycloakAuth;
                var helper = new angular_jwt_1.JwtHelperService();
                var userObject = helper.decodeToken(_this._auth.token);
                _this._userService.firstName.next(userObject.given_name);
                _this._userService.lastName.next(userObject.family_name);
                _this._userService.locale.next(userObject.locale);
                _this._userService.email.next(userObject.email);
                _this._userService.shop_uuid.next(userObject['shopLoginUuid']);
                resolve();
            })
                .error(function (error) {
                reject(error);
            });
        });
    };
    KeycloakService.prototype.logout = function (explicitLogoutUrl) {
        // console.log('*** LOGOUT');
        this._auth.logout();
        this._auth = null;
        // window.location.href = this.cleanUrl(KeycloakService.auth.logoutUrl, explicitLogoutUrl);
    };
    KeycloakService.prototype.getToken = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this._auth !== undefined && _this._auth.token) {
                _this._auth
                    .updateToken(5)
                    .success(function () {
                    resolve(_this._auth.token);
                })
                    .error(function () {
                    _this.logout();
                    // this._window.location.reload();
                    reject('Failed to refresh token');
                });
            }
            else {
                reject('Not logged in');
            }
        });
    };
    return KeycloakService;
}());
exports.KeycloakService = KeycloakService;
