"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PaginationComponent = /** @class */ (function () {
    function PaginationComponent() {
        this.setPage = new core_1.EventEmitter();
    }
    Object.defineProperty(PaginationComponent.prototype, "totalAmount", {
        set: function (v) {
            this._totalPages = Math.ceil(v / this.itemsPerPage);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginationComponent.prototype, "totalPages", {
        get: function () {
            return this._totalPages;
        },
        enumerable: true,
        configurable: true
    });
    //#endregion
    PaginationComponent.prototype.setActualPage = function (page) {
        this.setPage.emit(page);
    };
    return PaginationComponent;
}());
exports.PaginationComponent = PaginationComponent;
