import { Injectable } from '@angular/core';
import { IProtectionsRequestFilter } from '../models/protectionsRequestFilter.interface';

@Injectable()
export class SearchFilterService {
  //#region Properties
  private _hasAutoFilter: boolean;
  public get hasAutoFilter(): boolean {
    return this._hasAutoFilter;
  }

  private _autoFilter: IProtectionsRequestFilter;
  public get autoFilter(): IProtectionsRequestFilter {
    return this._autoFilter;
  }

  private _hasPermanentFilter: boolean;
  public get hasPermanentFilter(): boolean {
    return this._hasPermanentFilter;
  }

  private _permanentFilter: IProtectionsRequestFilter;
  public get permanentFilter(): IProtectionsRequestFilter {
    return this._permanentFilter;
  }
  //#endregion

  constructor() {
    this._autoFilter = JSON.parse(sessionStorage.getItem('auto_filter')) as IProtectionsRequestFilter;
    if (!this._autoFilter) {
      this._autoFilter = {};
    }
    if (!this._autoFilter.validFrom) {
      const date = new Date();
      date.setMonth(date.getMonth() - 2);
      this._autoFilter.validFrom = date.getTime();
    }
    if (!this._autoFilter.declarationStates) {
      this._autoFilter.declarationStates = 'DECLARED,FEEDBACK_NEEDED_SHOP,FEEDBACK_NEEDED_CONSUMER_AND_SHOP';
    }
    this._hasAutoFilter = this._autoFilter ? true : false;

    this._permanentFilter = JSON.parse(localStorage.getItem('filter')) as IProtectionsRequestFilter;
    this._hasPermanentFilter = this._permanentFilter ? true : false;
  }

  setAutoFilter(filter: IProtectionsRequestFilter) {
    sessionStorage.setItem('auto_filter', JSON.stringify(filter));
  }

  setPermanentFilter(filter: IProtectionsRequestFilter) {
    localStorage.setItem('filter', JSON.stringify(filter));
  }
}
