"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var DeclarationMessageComponent = /** @class */ (function () {
    function DeclarationMessageComponent() {
        this._openForm = new core_1.EventEmitter();
        this._buttonDisabled = false;
    }
    Object.defineProperty(DeclarationMessageComponent.prototype, "streamItem", {
        get: function () {
            return this._streamItem;
        },
        set: function (v) {
            this._streamItem = v;
            this._messageOpen = v.initiallyOpen;
            this._isOpened = v.initiallyOpen;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationMessageComponent.prototype, "openForm", {
        get: function () {
            return this._openForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationMessageComponent.prototype, "messageOpen", {
        get: function () {
            return this._messageOpen;
        },
        set: function (v) {
            this._messageOpen = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationMessageComponent.prototype, "isOpened", {
        get: function () {
            return this._isOpened;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationMessageComponent.prototype, "buttonDisabled", {
        get: function () {
            return this._buttonDisabled;
        },
        enumerable: true,
        configurable: true
    });
    //#endregion
    DeclarationMessageComponent.prototype.toggleView = function (event) {
        this._isOpened = event['toState'];
    };
    DeclarationMessageComponent.prototype.openItem = function () {
        if (!this._isOpened) {
            this._messageOpen = true;
        }
    };
    DeclarationMessageComponent.prototype.triggerFormOpen = function () {
        this._buttonDisabled = true;
        this._openForm.emit();
    };
    return DeclarationMessageComponent;
}());
exports.DeclarationMessageComponent = DeclarationMessageComponent;
