"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SearchFilterService = /** @class */ (function () {
    //#endregion
    function SearchFilterService() {
        this._autoFilter = JSON.parse(sessionStorage.getItem('auto_filter'));
        if (!this._autoFilter) {
            this._autoFilter = {};
        }
        if (!this._autoFilter.validFrom) {
            var date = new Date();
            date.setMonth(date.getMonth() - 2);
            this._autoFilter.validFrom = date.getTime();
        }
        if (!this._autoFilter.declarationStates) {
            this._autoFilter.declarationStates = 'DECLARED,FEEDBACK_NEEDED_SHOP,FEEDBACK_NEEDED_CONSUMER_AND_SHOP';
        }
        this._hasAutoFilter = this._autoFilter ? true : false;
        this._permanentFilter = JSON.parse(localStorage.getItem('filter'));
        this._hasPermanentFilter = this._permanentFilter ? true : false;
    }
    Object.defineProperty(SearchFilterService.prototype, "hasAutoFilter", {
        get: function () {
            return this._hasAutoFilter;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchFilterService.prototype, "autoFilter", {
        get: function () {
            return this._autoFilter;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchFilterService.prototype, "hasPermanentFilter", {
        get: function () {
            return this._hasPermanentFilter;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchFilterService.prototype, "permanentFilter", {
        get: function () {
            return this._permanentFilter;
        },
        enumerable: true,
        configurable: true
    });
    SearchFilterService.prototype.setAutoFilter = function (filter) {
        sessionStorage.setItem('auto_filter', JSON.stringify(filter));
    };
    SearchFilterService.prototype.setPermanentFilter = function (filter) {
        localStorage.setItem('filter', JSON.stringify(filter));
    };
    return SearchFilterService;
}());
exports.SearchFilterService = SearchFilterService;
