"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var DeclarationsProtectionInfosComponent = /** @class */ (function () {
    //#endregion
    function DeclarationsProtectionInfosComponent(translate) {
        this.translate = translate;
    }
    Object.defineProperty(DeclarationsProtectionInfosComponent.prototype, "protection", {
        get: function () {
            return this._protection;
        },
        set: function (v) {
            this._protection = v;
        },
        enumerable: true,
        configurable: true
    });
    return DeclarationsProtectionInfosComponent;
}());
exports.DeclarationsProtectionInfosComponent = DeclarationsProtectionInfosComponent;
