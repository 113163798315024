import { Component, OnInit } from '@angular/core';
import { DeclarationService } from '../../../core/services/declaration.service';
import { IMessageBanner } from '../../../../shared/message-banner/message-banner.interface';
import { IProtection } from '../../../core/models/protection.interface';
import { TranslateService } from '@ngx-translate/core';
import { IProtectionsRequestFilter } from '../../../core/models/protectionsRequestFilter.interface';
import { DeclarationState } from '../../../core/models/declarationState.enum';
import { SearchFilterService } from '../../../core/services/search-filter.service';

@Component({
  selector: 'app-declarations-list',
  templateUrl: './declarations-list.component.html',
  styleUrls: ['./declarations-list.component.scss']
})
export class DeclarationsListComponent implements OnInit {
  //#region Private Fields
  private readonly _declarationService: DeclarationService;
  private readonly _searchFilterService: SearchFilterService;
  private readonly _translateService: TranslateService;
  //#endregion

  //#region Properties
  private _messageBanner: IMessageBanner;
  public get messageBanner(): IMessageBanner {
    return this._messageBanner;
  }

  private _noSearchResultBanner: IMessageBanner;
  public get noSearchResultBanner(): IMessageBanner {
    return this._noSearchResultBanner;
  }

  private _protections: Array<IProtection>;
  public get protections(): Array<IProtection> {
    return this._protections;
  }

  private _totalAmount: number;
  public get totalAmount(): number {
    return this._totalAmount;
  }

  private _currentPage: number;
  public get currentPage(): number {
    return this._currentPage;
  }

  private _requestFilter: IProtectionsRequestFilter = {
    declarationStates: 'DECLARED,FEEDBACK_NEEDED_SHOP,FEEDBACK_NEEDED_CONSUMER_AND_SHOP',
    offset: 0
  };

  private _isLoading = false;
  public get isLoading(): boolean {
    return this._isLoading;
  }
  //#endregion

  constructor(declarationService: DeclarationService, searchFilterService: SearchFilterService, translateService: TranslateService) {
    this._declarationService = declarationService;
    this._searchFilterService = searchFilterService;
    this._translateService = translateService;
  }

  ngOnInit() {
    if (this._searchFilterService.hasAutoFilter) {
      this._requestFilter = this._searchFilterService.autoFilter;
    }
    if (sessionStorage.getItem('message_type')) {
      const messageType = JSON.parse(sessionStorage.getItem('message_type'));

      if (messageType.type === 'SAVED') {
        this._translateService.get([
          'DECLARATION_MESSAGE_BANNER.CLOSED_HEADLINE',
          'DECLARATION_MESSAGE_BANNER.CLOSED_TEXT'], {protectionId: messageType.protectionNumber}).subscribe(next => {
          this._messageBanner = {
            headline: next['DECLARATION_MESSAGE_BANNER.CLOSED_HEADLINE'],
            message: next[ 'DECLARATION_MESSAGE_BANNER.CLOSED_TEXT'],
            symbol: 'fa-info-circle',
            type: 'orange'
          };
        });
      } else if (messageType.type === 'SAVED_FOR_CHECK') {
        this._translateService.get([
          'DECLARATION_MESSAGE_BANNER.TS_CHECK_HEADLINE',
          'DECLARATION_MESSAGE_BANNER.TS_CHECK_TEXT']).subscribe(next => {
          this._messageBanner = {
            fadeOutAfter: 5000,
            headline: next['DECLARATION_MESSAGE_BANNER.TS_CHECK_HEADLINE'],
            message: next[ 'DECLARATION_MESSAGE_BANNER.TS_CHECK_TEXT'],
            symbol: 'fa-info-circle',
            type: 'blue'
          };
        });
      }

      sessionStorage.removeItem('message_type');
    }

    this._translateService.get([
      'DECLARATION_MESSAGE_BANNER.NO_RESULT_HEADLINE',
      'DECLARATION_MESSAGE_BANNER.NO_RESULT_TEXT']).subscribe(next => {
      this._noSearchResultBanner = {
        headline: next['DECLARATION_MESSAGE_BANNER.NO_RESULT_HEADLINE'],
        message: next[ 'DECLARATION_MESSAGE_BANNER.NO_RESULT_TEXT'],
        symbol: 'fa-info-circle',
        type: 'orange'
      };
    });

    // delete this._requestFilter.declarationStates;
    this.loadPage(1);
  }

  async loadPage(page: number) {
    try {
      this._isLoading = true;
      this._requestFilter.offset = (page - 1) * 30;
      const list = await this._declarationService.getProtections(this._requestFilter);
      this._totalAmount = list.totalAmount;
      this._protections = list.declarations.filter(
        x => x.declarationState !== DeclarationState.DECLARING && x.declarationState !== DeclarationState.TS_CHECKED
      );
      this._currentPage = page;
      delete this._requestFilter.offset;
      this._isLoading = false;
    } catch (e) {
      this._isLoading = false;
    }
  }

  setFilters(filter: IProtectionsRequestFilter): void {
    this._searchFilterService.setAutoFilter(filter);
    this._requestFilter = filter;
    this.loadPage(1);
  }
}
