import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DeclarationsListComponent} from '../views/declarations-list/declarations-list.component';
import { DeclarationsDetailsComponent } from '../views/declarations-details/declarations-details.component';

const routes: Routes = [
  { path: '', component: DeclarationsListComponent },
  { path: 'declaration/:protectionNumber/:protectionUuid', component: DeclarationsDetailsComponent },
  { path: 'declaration/:protectionNumber/:protectionUuid/:declarationUuid', component: DeclarationsDetailsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeclarationsRoutingModule { }
