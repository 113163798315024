"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var HtmlEncodePipe = /** @class */ (function () {
    function HtmlEncodePipe() {
    }
    HtmlEncodePipe.prototype.transform = function (value) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var div = document.createElement('div');
        div.innerText = value;
        return div.innerHTML;
    };
    return HtmlEncodePipe;
}());
exports.HtmlEncodePipe = HtmlEncodePipe;
