"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var Nl2BrPipe = /** @class */ (function () {
    //#endregion
    //#region Ctor
    function Nl2BrPipe(domSanitizer) {
        this._domSanitizer = domSanitizer;
    }
    //#endregion
    //#region Public Methods
    Nl2BrPipe.prototype.transform = function (value) {
        var htmlChunks = value.split(/\n/g);
        for (var i = 0; i < htmlChunks.length; i++) {
            htmlChunks[i] = this._domSanitizer.sanitize(core_1.SecurityContext.HTML, htmlChunks[i]);
        }
        return this._domSanitizer.bypassSecurityTrustHtml(htmlChunks.join('<br>'));
    };
    return Nl2BrPipe;
}());
exports.Nl2BrPipe = Nl2BrPipe;
