import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';
import { DeclarationsUiModule } from '../ui/declarations-ui.module';
import { DeclarationService } from './services/declaration.service';
import { SearchFilterService } from './services/search-filter.service';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [],
  exports: [
    DeclarationsUiModule
  ],
  providers: [
    DeclarationService,
    SearchFilterService
  ]
})
export class DeclarationsModule { }
