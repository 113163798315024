import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { IDocumentDisplayData } from '../../../core/models/document-display-data.interface';
import { DeclarationService } from '../../../core/services/declaration.service';
import { DocumentModalComponent } from '../document-modal/document-modal.component';

@Component({
  selector: 'app-declaration-document',
  templateUrl: './declarations-document.component.html',
  styleUrls: ['declarations-document.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeclarationsDocumentComponent implements OnInit {
  //#region Private Fields
  private readonly _declarationService: DeclarationService;
  //#endregion

  //#region Properties
  @Input() documentData: IDocumentDisplayData;

  @Input() declarationUuid: string;

  @Output() deleteDocument = new EventEmitter<IDocumentDisplayData>();

  @ViewChild(DocumentModalComponent) documentModal: DocumentModalComponent;

  private _showProgress = false;
  public get showProgress(): boolean {
    return this._showProgress;
  }

  private _showLoading = false;
  public get showLoading(): boolean {
    return this._showLoading;
  }

  private _showImage = false;
  public get showImage(): boolean {
    return this._showImage;
  }

  private _showError = false;
  public get showError(): boolean {
    return this._showError;
  }

  private _percentage = 0;
  public get percentage(): number {
    return this._percentage;
  }

  private _isDeleting = false;
  public get isDeleting(): boolean {
    return this._isDeleting;
  }

  private _disableDelete: boolean;
  @Input()
  public get disableDelete(): boolean {
    return this._disableDelete;
  }
  public set disableDelete(v: boolean) {
    this._disableDelete = v;
  }
  //#endregion

  constructor(declarationService: DeclarationService) {
    this._declarationService = declarationService;
  }


  public stripFileName(name: string): string {
    if (name.length > 12) {
      return `${name.substr(0, 7)}...${name.substr(name.length - 4)}`;
    } else {
      return name;
    }
  }

  ngOnInit() {
    if (this.documentData.error !== undefined) {
      this._showError = true;
    } else if (this.documentData.progress === undefined && this.documentData.document !== undefined) {
      this._showImage = true;
    } else if (this.documentData.progress !== undefined && this.documentData.document === undefined) {
      this._showProgress = true;
      const subscribe = this.documentData.progress.subscribe(next => {
        this._percentage = next;
      }, error => {
        this.documentData.error = 'GENERAL_ERROR';
        this._showProgress = false;
        this._showError = true;
      }, () => {
        this._showProgress = false;
        if (this.documentData.error !== undefined) {
          this._showError = true;
        } else {
          this._showImage = true;
        }
      });
    } else {
      this._showImage = true;
    }
  }

  deleteDoc(e) {
    e.stopPropagation();
    this._isDeleting = true;

    this.deleteDocument.emit(this.documentData);
    setTimeout(() => this._isDeleting = false, 10000);
  }

  showDocument() {
    this.documentModal.openModal();
  }

  private _pollForThumbnail() {
    this._showLoading = true;
    let index = 0;
    const interval = setInterval(async () => {
      if (this.documentData.document.previewContentBase64.length === 0 && index < 10) {
        try {
          this.documentData.document = await this._declarationService.getDocument(this.declarationUuid,
            this.documentData.document.uuid);
          index ++;
        } catch (e) {
          index ++;
        }
      } else if (this.documentData.document.previewContentBase64.length === 0 && index >= 10) {
        clearInterval(interval);
        this._showLoading = false;
        this._showImage = true;
      } else {
        clearInterval(interval);
        this._showLoading = false;
        this._showImage = true;
      }
    }, 1000);
  }
}

