"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var rxjs_1 = require("rxjs");
var DynamicDatePipe = /** @class */ (function () {
    //#endregion
    //#region Ctor
    function DynamicDatePipe(translateService) {
        this._translateService = translateService;
        this._datePipe = new common_1.DatePipe(this._translateService.currentLang);
    }
    //#endregion
    //#region Public Methods
    DynamicDatePipe.prototype.transform = function (value, format, timezone, locale) {
        var _this = this;
        var subject = new rxjs_1.ReplaySubject();
        subject.next(this.parse(value, format, timezone, this._translateService.currentLang));
        this._translateService
            .onLangChange
            .subscribe(function (x) {
            return subject.next(_this.parse(value, format, timezone, x.lang));
        });
        return subject;
    };
    //#endregion
    //#region Private Methods
    DynamicDatePipe.prototype.parse = function (value, format, timezone, locale) {
        if (!format) {
            format = 'shortDate';
        }
        return this._datePipe.transform(value, format, timezone, locale);
    };
    return DynamicDatePipe;
}());
exports.DynamicDatePipe = DynamicDatePipe;
