import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'nl2br'
})
export class Nl2BrPipe implements PipeTransform {
  //#region Private Fields
  private readonly _domSanitizer: DomSanitizer;
  //#endregion

  //#region Ctor
  public constructor(domSanitizer: DomSanitizer) {
    this._domSanitizer = domSanitizer;
  }
  //#endregion


  //#region Public Methods
  public transform(value: string): SafeHtml {
    const htmlChunks = value.split(/\n/g);
    for (let i = 0; i < htmlChunks.length; i++) {
      htmlChunks[i] = this._domSanitizer.sanitize(SecurityContext.HTML, htmlChunks[i]);
    }
    return this._domSanitizer.bypassSecurityTrustHtml(htmlChunks.join('<br>'));
  }
  //#endregion
}
