"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var search_filter_service_1 = require("../../../core/services/search-filter.service");
var material_1 = require("@angular/material");
var core_2 = require("@ngx-translate/core");
var FilterFormComponent = /** @class */ (function () {
    //#endregion
    function FilterFormComponent(searchFilterService, dateAdapter, translate, window) {
        //#endregion
        //#region Properties
        this._applyFilters = new core_1.EventEmitter();
        this._protectionStates = [
            'ALL_PROTECTIONS',
            'PROTECTION_WAITING_FOR_ACTION',
            'DECLARED_PROTECTIONS',
            'ENDED_PROTECTIONS'
        ];
        this._protectionState = 'PROTECTION_WAITING_FOR_ACTION';
        this._filterModel = {
            declarationStates: 'DECLARED,TS_CHECK',
        };
        this._hideAll = false;
        this._bottomRowHideOverflow = false;
        this._useSavedFilter = false;
        this._searchFilterService = searchFilterService;
        this._dateAdapter = dateAdapter;
        this._translate = translate;
        this._window = window;
    }
    Object.defineProperty(FilterFormComponent.prototype, "applyFilters", {
        get: function () {
            return this._applyFilters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterFormComponent.prototype, "protectionStates", {
        get: function () {
            return this._protectionStates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterFormComponent.prototype, "protectionState", {
        get: function () {
            return this._protectionState;
        },
        set: function (v) {
            if (v === 'ALL_PROTECTIONS') {
                this._filterModel.protectionStates = '';
                this._filterModel.declarationStates = '';
            }
            else if (v === 'PROTECTION_WAITING_FOR_ACTION') {
                this._filterModel.protectionStates = '';
                this._filterModel.declarationStates = 'DECLARED,FEEDBACK_NEEDED_SHOP,FEEDBACK_NEEDED_CONSUMER_AND_SHOP';
            }
            else if (v === 'ENDED_PROTECTIONS') {
                this._filterModel.protectionStates = 'CLOSED,DECISION_MADE';
                this._filterModel.declarationStates = '';
            }
            else {
                this._filterModel.protectionStates = '';
                this._filterModel.declarationStates = 'DECLARED,SHOP_ANSWERED,TS_CHECK,FEEDBACK_NEEDED_CONSUMER,FEEDBACK_NEEDED_SHOP,' +
                    'FEEDBACK_NEEDED_CONSUMER_AND_SHOP,TS_CHECKED,DECISION_MADE,WAITING_FOR_BANKACCOUNT_INFO,CONSUMER_CONFIRM';
            }
            this._protectionState = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterFormComponent.prototype, "filterModel", {
        get: function () {
            return this._filterModel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterFormComponent.prototype, "fromCalendarModel", {
        get: function () {
            return this._fromCalendarModel;
        },
        set: function (v) {
            this._fromCalendarModel = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterFormComponent.prototype, "toCalendarModel", {
        get: function () {
            return this._toCalendarModel;
        },
        set: function (v) {
            this._toCalendarModel = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterFormComponent.prototype, "hideAll", {
        get: function () {
            return this._hideAll;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterFormComponent.prototype, "bottomRowHideOverflow", {
        get: function () {
            return this._bottomRowHideOverflow;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterFormComponent.prototype, "useSavedFilter", {
        get: function () {
            return this._useSavedFilter;
        },
        enumerable: true,
        configurable: true
    });
    FilterFormComponent.prototype.ngOnInit = function () {
        if (this._window.matchMedia('(max-width: 767px)').matches) {
            this._hideAll = true;
            this._bottomRowHideOverflow = true;
        }
        this._dateAdapter.setLocale(this._translate.currentLang);
        if (this._searchFilterService.hasAutoFilter) {
            this._filterModel = this._searchFilterService.autoFilter;
            if (Object.keys(this._filterModel).indexOf('validFrom') > -1) {
                this._fromCalendarModel = new Date(this._filterModel.validFrom);
            }
            if (Object.keys(this._filterModel).indexOf('validTo') > -1) {
                this._toCalendarModel = new Date(this._filterModel.validTo);
            }
            if (this._filterModel.declarationStates === 'DECLARED,FEEDBACK_NEEDED_SHOP,FEEDBACK_NEEDED_CONSUMER_AND_SHOP') {
                this._protectionState = 'PROTECTION_WAITING_FOR_ACTION';
            }
            else if (this._filterModel.declarationStates === 'DECLARED,SHOP_ANSWERED,TS_CHECK,FEEDBACK_NEEDED_CONSUMER,FEEDBACK_NEEDED_SHOP,' +
                'FEEDBACK_NEEDED_CONSUMER_AND_SHOP,TS_CHECKED,DECISION_MADE,WAITING_FOR_BANKACCOUNT_INFO,CONSUMER_CONFIRM') {
                this._protectionState = 'DECLARED_PROTECTIONS';
            }
            else if (this._filterModel.protectionStates === 'CLOSED,DECISION_MADE') {
                this._protectionState = 'ENDED_PROTECTIONS';
            }
            else {
                this._protectionState = 'ALL_PROTECTIONS';
            }
        }
    };
    FilterFormComponent.prototype.setFilters = function () {
        var filter = Object.assign({}, this._filterModel);
        for (var key in filter) {
            if (filter[key] === 0 || filter[key] === '') {
                delete filter[key];
            }
        }
        this.applyFilters.emit(filter);
    };
    FilterFormComponent.prototype.setDate = function (event, type) {
        if (event.value) {
            this._filterModel[type] = event.value.getTime();
        }
        else {
            this._filterModel[type] = 0;
        }
        this.setFilters();
    };
    FilterFormComponent.prototype.formToggle = function () {
        this._hideAll = !this._hideAll;
    };
    FilterFormComponent.prototype.animationStart = function () {
        this._bottomRowHideOverflow = true;
    };
    FilterFormComponent.prototype.animationDone = function () {
        this._bottomRowHideOverflow = this._hideAll;
    };
    FilterFormComponent.prototype.dropDownChanged = function () {
        this.setFilters();
    };
    return FilterFormComponent;
}());
exports.FilterFormComponent = FilterFormComponent;
