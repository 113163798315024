"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var environment_1 = require("./environments/environment");
var __NgCli_bootstrap_1 = require("./app/app.module.ngfactory");
var __NgCli_bootstrap_2 = require("@angular/platform-browser");
var platformInitialized = false;
(window.bootstrapNg = function () {
    if (!platformInitialized && environment_1.environment.production) {
        core_1.enableProdMode();
    }
    platformInitialized = true;
    __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
        .catch(function (err) { return console.log(err); });
})();
