import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

let platformInitialized = false;
((<any>window).bootstrapNg = function() {
  if (!platformInitialized && environment.production) {
    enableProdMode();
  }

  platformInitialized = true;
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
})();
