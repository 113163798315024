import { Component, Input, ElementRef, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './core/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  //#region Properties
  private _myTsIntegrationActive = false;
  @Input()
  public get myTsIntegrationActive(): boolean {
    return this._myTsIntegrationActive;
  }
  public set myTsIntegrationActive(v: boolean) {
    this._myTsIntegrationActive = v;
  }

  private _shopIsMigrating = false;
  @Input()
  public get shopIsMigrating(): boolean {
    return this._shopIsMigrating;
  }
  public set shopIsMigrating(v: boolean) {
    this._shopIsMigrating = v;
  }

  private _queryParams = {};
  //#endregion

  //#region Ctor
  public constructor(
    private _translate: TranslateService,
    public element: ElementRef,
    public router: Router,
    private readonly _userService: UserService,
    public route: ActivatedRoute,
    @Inject('window') window: any) {
      if (window.location.search) {
        const searchParamsKV = window.location.search.split('?')[1].split('&');
        searchParamsKV.forEach(param => {
          this._queryParams[param.split('=')[0]] = param.split('=')[1];
        });
      }
    }
  //#endregion

  ngOnInit() {
    const componentElement = this.element.nativeElement as Element;

    const currentLanguageAttribute = componentElement.attributes.getNamedItem('currentLanguage');
    if (currentLanguageAttribute !== null) {
      this._translate.setDefaultLang(currentLanguageAttribute.value);
      this._translate.use(currentLanguageAttribute.value);
    } else {
      this._translate.setDefaultLang('de');
      this._translate.use('de');
    }

    this.route.queryParams.subscribe(x => {
      if (x.shopId) {
        this._userService.shopId.next(parseInt(x.shopId, 10));
      }

      if (x.declarationUuid && x.protectionUuid && x.protectionNumber) {
        setTimeout(() => this.router.navigateByUrl(`/declaration/${x.protectionNumber}/${x.protectionUuid}/${x.declarationUuid}`));
      }
    });

    if (Object.keys(this._queryParams).includes('shopId')) {
      this._userService.shopId.next(parseInt(this._queryParams['shopId'], 10));
    }

    if (Object.keys(this._queryParams).includes('selectedShopId')) {
      this._userService.shopId.next(parseInt(this._queryParams['selectedShopId'], 10));
    }

    if (Object.keys(this._queryParams).includes('declarationUuid')
      && Object.keys(this._queryParams).includes('protectionUuid')
      && Object.keys(this._queryParams).includes('protectionNumber')) {
      setTimeout(() => this.router.navigateByUrl(
        `/declaration/${this._queryParams['protectionNumber']}` +
        `/${this._queryParams['protectionUuid']}` +
        `/${this._queryParams['declarationUuid']}`));
    }

    const shopIdAttribute = componentElement.attributes.getNamedItem('shopid');
    if (shopIdAttribute !== null && shopIdAttribute.value && !isNaN(parseInt(shopIdAttribute.value, 10))) {
      this._userService.shopId.next(parseInt(shopIdAttribute.value, 10));
    }

    this.route.queryParams.subscribe(x => {
      if (x.selectedShopId && !isNaN(parseInt(x.selectedShopId, 10))) {
        this._userService.shopId.next(parseInt(x.selectedShopId, 10));
      }
    });

    const myTsIntegrationAttribute = componentElement.attributes.getNamedItem('mytsintegrationactive');
    if (myTsIntegrationAttribute === null) {
      return;
    }

    if (myTsIntegrationAttribute.value.toLowerCase() === 'true') {
      this._myTsIntegrationActive = true;
    }

    const shopMigratingAttribute = componentElement.attributes.getNamedItem('shopmigrating');
    if (shopMigratingAttribute === null) {
      return;
    }

    if (shopMigratingAttribute.value.toLowerCase() === 'true') {
      this.shopIsMigrating = true;
    }
  }
}
