<div class="container">
  <app-message-banner [messageBanner]="messageBanner"></app-message-banner>
  <app-filter-form (applyFilters)="setFilters($event)"></app-filter-form>
</div>
<hr>
<div class="container">
  <app-message-banner [messageBanner]="noSearchResultBanner" *ngIf="totalAmount === 0"></app-message-banner>
  <div class="row d-lg-none" *ngIf="totalAmount > 0">
    <div class="col-auto"><h5>{{ 'DECLARATION_LIST.LIST_HEADLINE' | translate }}</h5></div>
  </div>
  <div class="wrapper" *ngIf="totalAmount > 0">
    <table class="table">
      <thead>
      <tr>
        <th colspan="6">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto"><h3>{{ 'DECLARATION_LIST.LIST_HEADLINE' | translate }}</h3></div>
            <div class="col-auto">
              <app-pagination [currentPage]="currentPage" [itemsPerPage]="30" [totalAmount]="totalAmount" (setPage)="loadPage($event)"></app-pagination>
            </div>
          </div>
        </th>
      </tr>
      </thead>
      <thead>
        <tr>
          <th>{{ 'DECLARATION_LIST.EMAIL' | translate }}</th>
          <th>{{ 'DECLARATION_LIST.STATUS' | translate }}</th>
          <th>{{ 'DECLARATION_LIST.ORDER_DATE' | translate }}</th>
          <th>{{ 'DECLARATION_LIST.ORDER_NUMBER' | translate }}</th>
          <th colspan="2">{{ 'DECLARATION_LIST.PROTECTION_NUMBER' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let protection of protections">
          <td class="d-lg-none mobile-info">
            <div class="values">
              <div class="values-row">
                <div class="values-cell">{{ 'DECLARATION_LIST.EMAIL' | translate }}:</div>
                <div class="values-cell mail">{{ protection.consumerEmail }}</div>
              </div>
              <div class="values-row">
                <div class="values-cell">{{ 'DECLARATION_LIST.PROTECTION_NUMBER_LONG' | translate }}:</div>
                <div class="values-cell">{{ protection.protectionNumber }}</div>
              </div>
              <div class="values-row">
                <div class="values-cell">{{ 'DECLARATION_LIST.STATUS' | translate }}:</div>
                <div class="values-cell">
                  <a [routerLink]="'declaration/' + protection.protectionNumber + '/' + protection.protectionUuid + '/' + protection.declarationUuid"><span class="state-badge" [ngClass]="protection.declarationState" *ngIf="protection.declarationState">{{ 'DECLARATION_STATES.' + protection.declarationState | translate }}</span></a>
                  <a [routerLink]="'declaration/' + protection.protectionNumber + '/' + protection.protectionUuid"><span class="state-badge green" *ngIf="!protection.declarationState && protection.protectionState !== 'CLOSED'">{{ 'DECLARATION_STATES.ACTIVE' | translate }}</span></a>
                  <a [routerLink]="'declaration/' + protection.protectionNumber + '/' + protection.protectionUuid"><span class="state-badge" *ngIf="!protection.declarationState && protection.protectionState === 'CLOSED'">{{ 'DECLARATION_STATES.CLOSED' | translate }}</span></a>
                </div>
              </div>
            </div>
            <div class="row justify-content-end mt-3">
              <div class="col-12 text-right">
                <button class="btn" *ngIf="protection.declarationUuid" [routerLink]="'declaration/' + protection.protectionNumber + '/' + protection.protectionUuid + '/' + protection.declarationUuid" [ngClass]="{
                'btn-ananas': protection.declarationState === 'DECLARED' ||
                    protection.declarationState === 'FEEDBACK_NEEDED_SHOP' ||
                    protection.declarationState === 'FEEDBACK_NEEDED_CONSUMER_AND_SHOP'
                }">
                  <span [innerText]="'CORE.DETAILS' | translate"></span>
                  <span class="fa fa-angle-right"></span>
                </button>
                <button class="btn" *ngIf="!protection.declarationUuid" [routerLink]="'declaration/' + protection.protectionNumber + '/' + protection.protectionUuid">
                  <span [innerText]="'CORE.DETAILS' | translate"></span>
                  <span class="fa fa-angle-right"></span>
                </button>
              </div>
            </div>
          </td>
          <td class="mail d-none d-lg-table-cell" [title]="protection.consumerEmail">{{ protection.consumerEmail }}</td>
          <td class="d-none d-lg-table-cell">
            <a [routerLink]="'declaration/' + protection.protectionNumber + '/' + protection.protectionUuid + '/' + protection.declarationUuid"><span class="state-badge" [ngClass]="protection.declarationState" *ngIf="protection.declarationState">{{ 'DECLARATION_STATES.' + protection.declarationState | translate }}</span></a>
            <a [routerLink]="'declaration/' + protection.protectionNumber + '/' + protection.protectionUuid"><span class="state-badge green" *ngIf="!protection.declarationState && protection.protectionState !== 'CLOSED'">{{ 'DECLARATION_STATES.ACTIVE' | translate }}</span></a>
            <a [routerLink]="'declaration/' + protection.protectionNumber + '/' + protection.protectionUuid"><span class="state-badge" *ngIf="!protection.declarationState && protection.protectionState === 'CLOSED'">{{ 'DECLARATION_STATES.CLOSED' | translate }}</span></a>
          </td>
          <td class="d-none d-lg-table-cell">{{ protection.orderDate | dynamicDate | async }}</td>
          <td class="order-number d-none d-lg-table-cell" [title]="protection.orderNumber">{{ protection.orderNumber }}</td>
          <td class="d-none d-lg-table-cell">{{ protection.protectionNumber }}</td>
          <td class="d-none d-lg-table-cell">
            <button class="btn" *ngIf="protection.declarationUuid" [routerLink]="'declaration/' + protection.protectionNumber + '/' + protection.protectionUuid + '/' + protection.declarationUuid" [ngClass]="{
                'btn-ananas': protection.declarationState === 'DECLARED' ||
                    protection.declarationState === 'FEEDBACK_NEEDED_SHOP' ||
                    protection.declarationState === 'FEEDBACK_NEEDED_CONSUMER_AND_SHOP'
                }">
              <span [innerText]="'CORE.DETAILS' | translate"></span>
              <span class="fa fa-angle-right"></span>
            </button>
            <button class="btn" *ngIf="!protection.declarationUuid" [routerLink]="'declaration/' + protection.protectionNumber + '/' + protection.protectionUuid">
              <span [innerText]="'CORE.DETAILS' | translate"></span>
              <span class="fa fa-angle-right"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row justify-content-center d-lg-none pagination-wrapper">
    <div class="col-auto">
      <app-pagination [currentPage]="currentPage" [itemsPerPage]="30" [totalAmount]="totalAmount" (setPage)="loadPage($event)"></app-pagination>
    </div>
  </div>
  <div class="row keys-wrapper">
    <div class="col-auto">
      <p>
        <span class="state-badge green">{{ 'DECLARATION_STATES.ACTIVE' | translate }}</span>
        {{ 'DECLARATION_STATES.KEY_ACTIVE' | translate }}
      </p>
      <p>
        <span class="state-badge ananas">{{ 'DECLARATION_STATES.DECLARED' | translate }}</span>
        {{ 'DECLARATION_STATES.KEY_FEEDBACK' | translate }}
      </p>
      <p>
        <span class="state-badge">{{ 'DECLARATION_STATES.SHOP_ANSWERED' | translate }}</span>
        {{ 'DECLARATION_STATES.KEY_WAITING' | translate }}
      </p>
      <p>
        <span class="state-badge">{{ 'DECLARATION_STATES.FEEDBACK_NEEDED_CONSUMER' | translate }}</span>
        {{ 'DECLARATION_STATES.KEY_CHECK' | translate }}
      </p>
      <p>
        <span class="state-badge">{{ 'DECLARATION_STATES.CLOSED' | translate }}</span>
        {{ 'DECLARATION_STATES.KEY_CLOSED' | translate }}
      </p>
    </div>
  </div>
</div>
<loading-overlay *ngIf="isLoading"></loading-overlay>
