import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { KeycloakService } from '../services/keycloak.service';
import { Observable ,  ReplaySubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { flatMap, map } from 'rxjs/operators';

@Injectable()
export class KeycloakInterceptor implements HttpInterceptor {
  //#region private fields
  private _keycloakService: KeycloakService;
  //#endregion

  //#region Properties
  protectedUrls = environment.keycloak.protectedUrls;
  //#endregion

  constructor(keycloakService: KeycloakService) {
    this._keycloakService = keycloakService;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.protectedUrls
      .map(x => x.test(req.url))
      .some(x => x)) {
      return next.handle(req);
    }
    const token = new ReplaySubject<string>();

    this._keycloakService.getToken()
      .then(res => {
        token.next(res);
      });

    return token.pipe(
      map(tokenReceived => {
        return req.clone({ headers: req.headers.set('Authorization', `Bearer ${tokenReceived}`)});
      }),
      flatMap(newReq => next.handle(newReq))
    );
  }
}
