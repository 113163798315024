import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IDeclaration } from '../models/declaration.interface';
import { IDeclarationMessage } from '../models/declarationMessage.interface';
import { DeclarationShopFeedback } from '../models/declarationShopFeedback.enum';
import { IProtectionsRequest } from '../models/protectionsRequest.interface';
import { IProtection } from '../models/protection.interface';
import { IDocument } from '../models/document.interface';
import { IDocumentDisplayData } from '../models/document-display-data.interface';
import { fileTypes } from '../../ui/configuration/fileTypes';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { UserService } from '../../../core/services/user.service';

@Injectable()
export class DeclarationService {
  //#region Private Fields
  private readonly _http: HttpClient;
  private readonly _userService: UserService;
  //#endregion

  constructor(http: HttpClient, userService: UserService) {
    this._http = http;
    this._userService = userService;
  }

  getDeclaration(declarationUuid: string): Promise<IDeclaration> {
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/`;
    return new Promise<IDeclaration>((resolve, reject) => {
      return this._http.get(`${url}${declarationUuid}`).subscribe(res => {
          resolve(res as IDeclaration);
        },
        err => {
          reject(err);
        });
    });
  }

  claimDeclaration(declarationUuid: string): Promise<IDeclaration> {
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/`;
    return new Promise<IDeclaration>((resolve, reject) => {
      return this._http.post(
        `${url}${declarationUuid}/claim`,
        '',
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(res as IDeclaration);
        },
        err => {
          reject(err);
        });
    });
  }

  updateDeclaration(declarationUuid: string, feedbackShop: DeclarationShopFeedback, amountRefund: number): Promise<boolean> {
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/`;
    const body = {
      amountRefund: `${amountRefund}`,
      feedbackShop
    };
    return new Promise<boolean>((resolve, reject) => {
      return this._http.put(
        `${url}${declarationUuid}`,
        JSON.stringify(body),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(true);
        },
        err => {
          reject(err);
        });
    });
  }

  commitFeedback(declarationUuid: string): Promise<boolean> {
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/`;
    return new Promise<boolean>((resolve, reject) => {
      return this._http.post(
        `${url}${declarationUuid}/commit-feedback`,
        '',
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(true);
        },
        err => {
          reject(err);
        });
    });
  }

  commitAdditionalInformation(declarationUuid: string): Promise<boolean> {
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/`;
    return new Promise<boolean>((resolve, reject) => {
      return this._http.post(
        `${url}${declarationUuid}/commit-additional-information`,
        '',
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(true);
        },
        err => {
          reject(err);
        });
    });
  }

  getMessagesForDeclaration(declarationUuid: string): Promise<IDeclarationMessage[]> {
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/`;
    return new Promise<IDeclarationMessage[]>((resolve, reject) => {
      return this._http.get(`${url}${declarationUuid}/messages`).subscribe(res => {
          resolve(res as IDeclarationMessage[]);
        },
        err => {
          reject(err);
        });
    });
  }

  createMessageForDeclaration(declarationUuid: string, message: string): Promise<IDeclarationMessage> {
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/`;

    return new Promise<IDeclarationMessage>((resolve, reject) => {
      const body = {
        message: message
      };

      return this._http.post(
        `${url}${declarationUuid}/messages`,
        JSON.stringify(body),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(res as IDeclarationMessage);
        },
        err => {
          reject(err);
        });
    });
  }

  updateMessageForDeclaration(declarationUuid: string, messageUuid: string, message: string): Promise<IDeclarationMessage> {
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/`;

    return new Promise<IDeclarationMessage>((resolve, reject) => {
      const body = {
        message: message
      };

      return this._http.put(
        `${url}${declarationUuid}/messages/${messageUuid}`,
        JSON.stringify(body),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(res as IDeclarationMessage);
        },
        err => {
          reject(err);
        });
    });
  }

  attachDocumentToMessage(declarationUuid: string, messageUuid: string, documentUuids: string[]): Promise<IDeclarationMessage> {
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/`;

    return new Promise<IDeclarationMessage>((resolve, reject) => {
      const body = {
        documentUuids: documentUuids,
        action: 'UPLOAD'
      };

      return this._http.patch(
        `${url}${declarationUuid}/messages/${messageUuid}`,
        JSON.stringify(body),
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json')
        }).subscribe(res => {
          resolve(res as IDeclarationMessage);
        },
        err => {
          reject(err);
        });
    });
  }

  getProtections(filters?: object): Promise<IProtectionsRequest> {
    let url =
      `${environment.protections.ordersApi}uuid${this._userService.shop_uuid.value}/shop${this._userService.shopId.value}/protections`;
    const params = new Array<string>();

    if (filters) {
      for (const filter of Object.keys(filters)) {
        params.push(filter + '=' + encodeURI(filters[filter]));
      }

      url = `${url}?${params.join('&')}`.replace(/\+/g, '%2B');
    }

    return new Promise<IProtectionsRequest>((resolve, reject) => {
      return this._http.get(url).subscribe(res => {
          resolve(res as IProtectionsRequest);
        },
        err => {
          reject(err);
        });
    });
  }

  getProtection(uuid: string): Promise<IProtection> {
    const url =
      `${environment.protections.ordersApi}uuid${this._userService.shop_uuid.value}/shop${this._userService.shopId.value}/protections`;

    return new Promise<IProtection>((resolve, reject) => {
      return this._http.get(`${url}/${uuid}`).subscribe(res => {
          resolve(res as IProtection);
        },
        err => {
          reject(err);
        });
    });
  }

  public async upload(declarationUuid: string, files: Array<File>): Promise<Array<IDocumentDisplayData>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/${declarationUuid}/documents`;
    const status: Array<IDocumentDisplayData> = [];

    await this._asyncForEach(files, async (file) => {
      const data = {} as IDocumentDisplayData;

      if (!fileTypes.includes(file.type)) {
        data.error = 'WRONG_TYPE';
        data.document = {fileName: file.name} as IDocument;
      } else if (file.size > 10000000) {
        data.error = 'FILE_SIZE';
        data.document = {fileName: file.name} as IDocument;
      } else {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        const request = new HttpRequest('POST', url, formData, {reportProgress: true});
        const progress = new BehaviorSubject<number>(0);

        this._http.request(request).subscribe(event => {
          if (event.type === HttpEventType.UploadProgress) {
            const percent = Math.round(100 * event.loaded / event.total);
            progress.next(percent);
          } else if (event instanceof HttpResponse) {
            data.document = event.body as IDocument;
            progress.complete();
          }
        }, error => {
          if (error instanceof HttpErrorResponse
            && error.error
            && error.error.message.indexOf('FileSizeLimitExceededException') > -1) {
            data.error = 'FILE_SIZE';
          } else if (error instanceof HttpErrorResponse && error.status === 400) {
            data.error = 'WRONG_TYPE';
          } else {
            data.error = 'GENERAL_ERROR';
          }

          data.document = {fileName: file.name} as IDocument;
          progress.complete();
        });

        data.progress = progress.asObservable();
        await new Promise(resolve => setTimeout(resolve, 100));
      }


      status.push(data);
    });

    return status;
  }

  public getDocuments(declarationUuid: string): Promise<Array<IDocument>> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/${declarationUuid}/documents`;

    return new Promise<Array<IDocument>>(((resolve, reject) => {
      this._http.get(url).subscribe(res => {
          resolve(res as Array<IDocument>);
        },
        error => {
          reject(error);
        });
    }));
  }

  public getDocument(declarationUuid: string, documentUuid: string): Promise<IDocument> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/${declarationUuid}/documents/${documentUuid}`;

    return new Promise<IDocument>(((resolve, reject) => {
      this._http.get(url).subscribe(res => {
          resolve(res as IDocument);
        },
        error => {
          reject(error);
        });
    }));
  }

  public deleteDocument(declarationUuid: string, documentUuid: string): Promise<boolean> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/${declarationUuid}/documents/${documentUuid}`;

    return new Promise<boolean>(((resolve, reject) => {
      this._http.delete(url).subscribe(res => {
          resolve(true);
        },
        error => {
          reject(error);
        });
    }));
  }

  public getDocumentBlob(declarationUuid: string, documentUuid: string): Promise<Blob> {
    // tslint:disable-next-line:max-line-length
    const url = `${environment.protections.declarationAPI}uuid${this._userService.shop_uuid.value}/declarations/${declarationUuid}/documents/${documentUuid}/download`;

    return new Promise<Blob>(
      (resolve, reject) => {
        this._http.get(url, { responseType: 'blob' })
          .subscribe(
            x => resolve(x),
            err => reject(err)
          );
      }
    );
  }

  private async _asyncForEach(arr: Array<any>, callback: (x: any, i?: number, a?: Array<any>) => Promise<any>): Promise<any> {
    for (let i = 0; i < arr.length; i++) {
      await callback(arr[i], i, arr);
    }
  }
}
