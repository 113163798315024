import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formattedNumberValidator][ngModel],[formattedNumberValidator][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormattedNumberValidator),
      multi: true
    }
  ]
})
export class FormattedNumberValidator implements Validator {
  //#region Private Fields
  private readonly _translateService: TranslateService;
  //#endregion

  //#region Properties
  @Input() formattedNumberValidator: {min: number; max: number; byPass: boolean; };
  //#endregion

  constructor(translateService: TranslateService) {
    this._translateService = translateService;
  }

  //#region Public Methods
  public validate(control: AbstractControl): any {
    if (control.value !== undefined
      && control.value !== null
      && this._parseFloat(control.value) > this.formattedNumberValidator.min
      && this._parseFloat(control.value) <= this.formattedNumberValidator.max
      || this.formattedNumberValidator.byPass) {
      return null;
    }

    if (control.value !== null
      && this._parseFloat(control.value) === this.formattedNumberValidator.min) {
      return {
        reason: 'IS_MINIMUM',
        valid: false
      };
    }

    if (control.value !== null
      && this._parseFloat(control.value) > this.formattedNumberValidator.max) {
      return {
        reason: 'MORE_THAN_MAXIMUM',
        valid: false
      };
    }

    return {
      reason: 'IS_MINIMUM',
      valid: false
    };
  }

  private _parseFloat(value: string): number {
    const decimal = getLocaleNumberSymbol(this._translateService.currentLang, NumberSymbol.CurrencyDecimal);
    const group = getLocaleNumberSymbol(this._translateService.currentLang, NumberSymbol.CurrencyGroup);

    const parsed = value.replace(group, '').replace(decimal, '.');
    return parseFloat(parsed);
  }
  //#endregion
}
