"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DeclarationShopFeedback;
(function (DeclarationShopFeedback) {
    DeclarationShopFeedback["REFUND"] = "REFUND";
    DeclarationShopFeedback["WAIT_FOR_RETURN_OF_SHIPMENT"] = "WAIT_FOR_RETURN_OF_SHIPMENT";
    DeclarationShopFeedback["NON_REFUND"] = "NON_REFUND";
    DeclarationShopFeedback["PARTIAL_REFUND"] = "PARTIAL_REFUND";
    DeclarationShopFeedback["REFUND_LATER"] = "REFUND_LATER";
    DeclarationShopFeedback["PARTIAL_REFUND_LATER"] = "PARTIAL_REFUND_LATER";
})(DeclarationShopFeedback = exports.DeclarationShopFeedback || (exports.DeclarationShopFeedback = {}));
