import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IMessageStreamItem } from '../../../core/models/messageStreamItem.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-declaration-message',
  templateUrl: './declaration-message.component.html',
  styleUrls: [
    './declaration-message.component.scss'
  ],
  animations: [
    trigger('parent', [
      transition(':enter', [])
    ]),
    trigger('open', [
      state('false', style({
        height: 0
      })),
      state('true', style({
        height: '*'
      })),
      transition('* => *', animate('0.3s ease-in-out'))
    ]),
    trigger('boldHeadline', [
      state('false', style({
        marginBottom: 0
      })),
      state('true', style({
        marginBottom: '15px'
      })),
      transition('* => *', animate('0.3s ease-in-out'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class DeclarationMessageComponent {
  //#region Properties
  private _streamItem: IMessageStreamItem;
  public get streamItem(): IMessageStreamItem {
    return this._streamItem;
  }
  @Input() public set streamItem(v: IMessageStreamItem) {
    this._streamItem = v;
    this._messageOpen = v.initiallyOpen;
    this._isOpened = v.initiallyOpen;
  }

  private _openForm = new EventEmitter<any>();
  @Output() public get openForm(): EventEmitter<any> {
    return this._openForm;
  }

  private _messageOpen: boolean;
  public get messageOpen(): boolean {
    return this._messageOpen;
  }
  public set messageOpen(v: boolean) {
    this._messageOpen = v;
  }

  private _isOpened: boolean;
  public get isOpened(): boolean {
    return this._isOpened;
  }

  private _buttonDisabled = false;
  public get buttonDisabled(): boolean {
    return this._buttonDisabled;
  }
  //#endregion

  toggleView(event: AnimationEvent) {
    this._isOpened = event['toState'];
  }

  openItem() {
    if (!this._isOpened) {
      this._messageOpen = true;
    }
  }

  triggerFormOpen() {
    this._buttonDisabled = true;
    this._openForm.emit();
  }
}
