"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var declaration_service_1 = require("../../../core/services/declaration.service");
var core_2 = require("@ngx-translate/core");
var declarationState_enum_1 = require("../../../core/models/declarationState.enum");
var search_filter_service_1 = require("../../../core/services/search-filter.service");
var DeclarationsListComponent = /** @class */ (function () {
    //#endregion
    function DeclarationsListComponent(declarationService, searchFilterService, translateService) {
        this._requestFilter = {
            declarationStates: 'DECLARED,FEEDBACK_NEEDED_SHOP,FEEDBACK_NEEDED_CONSUMER_AND_SHOP',
            offset: 0
        };
        this._isLoading = false;
        this._declarationService = declarationService;
        this._searchFilterService = searchFilterService;
        this._translateService = translateService;
    }
    Object.defineProperty(DeclarationsListComponent.prototype, "messageBanner", {
        get: function () {
            return this._messageBanner;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsListComponent.prototype, "noSearchResultBanner", {
        get: function () {
            return this._noSearchResultBanner;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsListComponent.prototype, "protections", {
        get: function () {
            return this._protections;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsListComponent.prototype, "totalAmount", {
        get: function () {
            return this._totalAmount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsListComponent.prototype, "currentPage", {
        get: function () {
            return this._currentPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsListComponent.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    DeclarationsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this._searchFilterService.hasAutoFilter) {
            this._requestFilter = this._searchFilterService.autoFilter;
        }
        if (sessionStorage.getItem('message_type')) {
            var messageType = JSON.parse(sessionStorage.getItem('message_type'));
            if (messageType.type === 'SAVED') {
                this._translateService.get([
                    'DECLARATION_MESSAGE_BANNER.CLOSED_HEADLINE',
                    'DECLARATION_MESSAGE_BANNER.CLOSED_TEXT'
                ], { protectionId: messageType.protectionNumber }).subscribe(function (next) {
                    _this._messageBanner = {
                        headline: next['DECLARATION_MESSAGE_BANNER.CLOSED_HEADLINE'],
                        message: next['DECLARATION_MESSAGE_BANNER.CLOSED_TEXT'],
                        symbol: 'fa-info-circle',
                        type: 'orange'
                    };
                });
            }
            else if (messageType.type === 'SAVED_FOR_CHECK') {
                this._translateService.get([
                    'DECLARATION_MESSAGE_BANNER.TS_CHECK_HEADLINE',
                    'DECLARATION_MESSAGE_BANNER.TS_CHECK_TEXT'
                ]).subscribe(function (next) {
                    _this._messageBanner = {
                        fadeOutAfter: 5000,
                        headline: next['DECLARATION_MESSAGE_BANNER.TS_CHECK_HEADLINE'],
                        message: next['DECLARATION_MESSAGE_BANNER.TS_CHECK_TEXT'],
                        symbol: 'fa-info-circle',
                        type: 'blue'
                    };
                });
            }
            sessionStorage.removeItem('message_type');
        }
        this._translateService.get([
            'DECLARATION_MESSAGE_BANNER.NO_RESULT_HEADLINE',
            'DECLARATION_MESSAGE_BANNER.NO_RESULT_TEXT'
        ]).subscribe(function (next) {
            _this._noSearchResultBanner = {
                headline: next['DECLARATION_MESSAGE_BANNER.NO_RESULT_HEADLINE'],
                message: next['DECLARATION_MESSAGE_BANNER.NO_RESULT_TEXT'],
                symbol: 'fa-info-circle',
                type: 'orange'
            };
        });
        // delete this._requestFilter.declarationStates;
        this.loadPage(1);
    };
    DeclarationsListComponent.prototype.loadPage = function (page) {
        return __awaiter(this, void 0, void 0, function () {
            var list, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this._isLoading = true;
                        this._requestFilter.offset = (page - 1) * 30;
                        return [4 /*yield*/, this._declarationService.getProtections(this._requestFilter)];
                    case 1:
                        list = _a.sent();
                        this._totalAmount = list.totalAmount;
                        this._protections = list.declarations.filter(function (x) { return x.declarationState !== declarationState_enum_1.DeclarationState.DECLARING && x.declarationState !== declarationState_enum_1.DeclarationState.TS_CHECKED; });
                        this._currentPage = page;
                        delete this._requestFilter.offset;
                        this._isLoading = false;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        this._isLoading = false;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DeclarationsListComponent.prototype.setFilters = function (filter) {
        this._searchFilterService.setAutoFilter(filter);
        this._requestFilter = filter;
        this.loadPage(1);
    };
    return DeclarationsListComponent;
}());
exports.DeclarationsListComponent = DeclarationsListComponent;
