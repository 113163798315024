import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { IProtectionsRequestFilter } from '../../../core/models/protectionsRequestFilter.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SearchFilterService } from '../../../core/services/search-filter.service';
import { DateAdapter, MatDatepickerInputEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { EuropeDateAdapter } from '../../../../shared/europeDateAdapter';

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: [
    './filter-form.component.scss'
  ],
  animations: [
    trigger('bottomRowHidden', [
      state('false', style({
        maxHeight: '220px',
        marginBottom: '15px',
      })),
      state('true', style({
        maxHeight: '*',
        marginBottom: '*',
      })),
      transition('* => *', animate('0.3s ease-in-out'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class FilterFormComponent implements OnInit {
  //#region Private Fields
  private readonly _searchFilterService: SearchFilterService;
  private readonly _dateAdapter: DateAdapter<EuropeDateAdapter>;
  private readonly _translate: TranslateService;
  private readonly _window: any;
  //#endregion

  //#region Properties

  private _applyFilters = new EventEmitter<IProtectionsRequestFilter>();
  @Output() public get applyFilters(): EventEmitter<IProtectionsRequestFilter> {
    return this._applyFilters;
  }

  private _protectionStates = [
    'ALL_PROTECTIONS',
    'PROTECTION_WAITING_FOR_ACTION',
    'DECLARED_PROTECTIONS',
    'ENDED_PROTECTIONS'
  ];
  public get protectionStates(): Array<string> {
    return this._protectionStates;
  }

  private _protectionState = 'PROTECTION_WAITING_FOR_ACTION';
  public get protectionState(): string {
    return this._protectionState;
  }
  public set protectionState(v: string) {
    if (v === 'ALL_PROTECTIONS') {
      this._filterModel.protectionStates = '';
      this._filterModel.declarationStates = '';
    } else if (v === 'PROTECTION_WAITING_FOR_ACTION') {
      this._filterModel.protectionStates = '';
      this._filterModel.declarationStates = 'DECLARED,FEEDBACK_NEEDED_SHOP,FEEDBACK_NEEDED_CONSUMER_AND_SHOP';
    } else if (v === 'ENDED_PROTECTIONS') {
      this._filterModel.protectionStates = 'CLOSED,DECISION_MADE';
      this._filterModel.declarationStates = '';
    } else {
      this._filterModel.protectionStates = '';
      this._filterModel.declarationStates = 'DECLARED,SHOP_ANSWERED,TS_CHECK,FEEDBACK_NEEDED_CONSUMER,FEEDBACK_NEEDED_SHOP,' +
        'FEEDBACK_NEEDED_CONSUMER_AND_SHOP,TS_CHECKED,DECISION_MADE,WAITING_FOR_BANKACCOUNT_INFO,CONSUMER_CONFIRM';
    }
    this._protectionState = v;
  }

  private _filterModel: IProtectionsRequestFilter = {
    declarationStates: 'DECLARED,TS_CHECK',
  };
  public get filterModel(): IProtectionsRequestFilter {
    return this._filterModel;
  }

  private _fromCalendarModel: Date;
  public get fromCalendarModel(): Date {
    return this._fromCalendarModel;
  }
  public set fromCalendarModel(v: Date) {
    this._fromCalendarModel = v;
  }

  private _toCalendarModel: Date;
  public get toCalendarModel(): Date {
    return this._toCalendarModel;
  }
  public set toCalendarModel(v: Date) {
    this._toCalendarModel = v;
  }

  private _hideAll = false;
  public get hideAll(): boolean {
    return this._hideAll;
  }

  private _bottomRowHideOverflow = false;
  public get bottomRowHideOverflow(): boolean {
    return this._bottomRowHideOverflow;
  }

  private _useSavedFilter = false;
  public get useSavedFilter(): boolean {
    return this._useSavedFilter;
  }

  //#endregion

  constructor(searchFilterService: SearchFilterService,
              dateAdapter: DateAdapter<EuropeDateAdapter>,
              translate: TranslateService,
              @Inject('window') window: any) {
    this._searchFilterService = searchFilterService;
    this._dateAdapter = dateAdapter;
    this._translate = translate;
    this._window = window;
  }

  ngOnInit() {
    if (this._window.matchMedia('(max-width: 767px)').matches) {
      this._hideAll = true;
      this._bottomRowHideOverflow = true;
    }
    this._dateAdapter.setLocale(this._translate.currentLang);
    if (this._searchFilterService.hasAutoFilter) {
      this._filterModel = this._searchFilterService.autoFilter;

      if (Object.keys(this._filterModel).indexOf('validFrom') > -1) {
        this._fromCalendarModel = new Date(this._filterModel.validFrom);
      }

      if (Object.keys(this._filterModel).indexOf('validTo') > -1) {
        this._toCalendarModel = new Date(this._filterModel.validTo);
      }

      if (this._filterModel.declarationStates === 'DECLARED,FEEDBACK_NEEDED_SHOP,FEEDBACK_NEEDED_CONSUMER_AND_SHOP') {
        this._protectionState = 'PROTECTION_WAITING_FOR_ACTION';
      } else if (this._filterModel.declarationStates === 'DECLARED,SHOP_ANSWERED,TS_CHECK,FEEDBACK_NEEDED_CONSUMER,FEEDBACK_NEEDED_SHOP,' +
        'FEEDBACK_NEEDED_CONSUMER_AND_SHOP,TS_CHECKED,DECISION_MADE,WAITING_FOR_BANKACCOUNT_INFO,CONSUMER_CONFIRM') {
        this._protectionState = 'DECLARED_PROTECTIONS';
      } else if (this._filterModel.protectionStates === 'CLOSED,DECISION_MADE') {
        this._protectionState = 'ENDED_PROTECTIONS';
      } else {
        this._protectionState = 'ALL_PROTECTIONS';
      }

    }
  }

  setFilters(): void {
    const filter = Object.assign({}, this._filterModel);
    for (const key in filter) {
     if (filter[key] === 0 || filter[key] === '') {
       delete filter[key];
     }
    }
    this.applyFilters.emit(filter);
  }

  setDate(event: MatDatepickerInputEvent<Date>, type: string) {
    if (event.value) {
      this._filterModel[type] = event.value.getTime();
    } else {
      this._filterModel[type] = 0;
    }
    this.setFilters();
  }

  formToggle() {
    this._hideAll = !this._hideAll;
  }

  animationStart() {
    this._bottomRowHideOverflow = true;
  }

  animationDone() {
    this._bottomRowHideOverflow = this._hideAll;
  }

  dropDownChanged() {
    this.setFilters();
  }
}
