import { Component, Inject, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { IDocumentDisplayData } from '../../../core/models/document-display-data.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DeclarationService } from '../../../core/services/declaration.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-document-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.scss']
})
export class DocumentModalComponent {
  private readonly _declarationService: DeclarationService;
  private readonly _sanitizerService: DomSanitizer;
  private readonly _window: any;
  //#region Properties
  @ViewChild(ModalDirective) documentModal: ModalDirective;

  @Input() documentData: IDocumentDisplayData;

  private _declarationUuid: string;
  @Input() set declarationUuid(v: string) {
    this._declarationUuid = v;
  }

  private _documentUuid: string;

  private _fileName: string;
  public get fileName(): string {
    return this._fileName;
  }

  private _noInlinePreview = false;
  public get noInlinePreview(): boolean {
    return this._noInlinePreview;
  }

  private _alreadyLoaded = false;

  private _previewUrl: SafeResourceUrl;
  public get previewUrl(): SafeResourceUrl {
    return this._previewUrl;
  }

  public get extension(): string {
    const extensionIndex = this._fileName.lastIndexOf('.');
    return extensionIndex >= 0
      ? this._fileName.substr(extensionIndex + 1)
      : this._fileName;
  }
  //#endregion

  public constructor(declarationService: DeclarationService, sanitizerService: DomSanitizer, @Inject('window') window: any) {
    this._declarationService = declarationService;
    this._sanitizerService = sanitizerService;
    this._window = window;
  }

  async openModal() {
    if (!this._alreadyLoaded) {
      this._alreadyLoaded = true;

      this._documentUuid = this.documentData.document.uuid;
      this._fileName = this.documentData.document.fileName;

      if (!this.documentData.document.fileName || !this.inlinePreviewPossible(this.documentData.document.fileName)) {
        this._noInlinePreview = true;
        this.documentModal.show();
        return;
      }

      const blob = await this._declarationService.getDocumentBlob(this._declarationUuid, this._documentUuid);
      this._previewUrl = this._sanitizerService.bypassSecurityTrustResourceUrl(this._window.URL.createObjectURL(blob));
    }
    this.documentModal.show();
  }



  public async downloadDocument(): Promise<void> {
    const blob = await this._declarationService.getDocumentBlob(this._declarationUuid, this._documentUuid);
    saveAs(blob, this._fileName);
    this.documentModal.hide();
  }

  private inlinePreviewPossible(filename: string): boolean {
    return [
      '.png',
      '.jpg',
      '.jpeg',
      '.tif',
      '.tiff'
    ].some(x => filename.toLowerCase().indexOf(x) === filename.length - x.length);
  }
}
