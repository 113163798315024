"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var common_1 = require("@angular/common");
var core_1 = require("@ngx-translate/core");
var FormattedNumberValidator = /** @class */ (function () {
    //#endregion
    function FormattedNumberValidator(translateService) {
        this._translateService = translateService;
    }
    //#region Public Methods
    FormattedNumberValidator.prototype.validate = function (control) {
        if (control.value !== undefined
            && control.value !== null
            && this._parseFloat(control.value) > this.formattedNumberValidator.min
            && this._parseFloat(control.value) <= this.formattedNumberValidator.max
            || this.formattedNumberValidator.byPass) {
            return null;
        }
        if (control.value !== null
            && this._parseFloat(control.value) === this.formattedNumberValidator.min) {
            return {
                reason: 'IS_MINIMUM',
                valid: false
            };
        }
        if (control.value !== null
            && this._parseFloat(control.value) > this.formattedNumberValidator.max) {
            return {
                reason: 'MORE_THAN_MAXIMUM',
                valid: false
            };
        }
        return {
            reason: 'IS_MINIMUM',
            valid: false
        };
    };
    FormattedNumberValidator.prototype._parseFloat = function (value) {
        var decimal = common_1.getLocaleNumberSymbol(this._translateService.currentLang, common_1.NumberSymbol.CurrencyDecimal);
        var group = common_1.getLocaleNumberSymbol(this._translateService.currentLang, common_1.NumberSymbol.CurrencyGroup);
        var parsed = value.replace(group, '').replace(decimal, '.');
        return parseFloat(parsed);
    };
    return FormattedNumberValidator;
}());
exports.FormattedNumberValidator = FormattedNumberValidator;
