"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var declarationState_enum_1 = require("../../../core/models/declarationState.enum");
var core_2 = require("@ngx-translate/core");
var declaration_service_1 = require("../../../core/services/declaration.service");
var router_1 = require("@angular/router");
var createNumberMask_1 = require("text-mask-addons/dist/createNumberMask");
var common_1 = require("@angular/common");
var declarationShopFeedback_enum_1 = require("../../../core/models/declarationShopFeedback.enum");
var user_service_1 = require("../../../../core/services/user.service");
var error_modal_component_1 = require("../error-modal/error-modal.component");
var DeclarationsDetailsComponent = /** @class */ (function () {
    //#endregion
    function DeclarationsDetailsComponent(route, declarationService, userService, translateService, router) {
        this._message = '';
        this._showForm = 'hide';
        this._locale = 'de';
        this._textMask = createNumberMask_1.default({
            prefix: '',
            decimalSymbol: ',',
            thousandsSeparatorSymbol: '.',
            allowDecimal: true,
            decimalLimit: 2
        });
        this._uploadDocuments = [];
        this._isLoading = false;
        this._route = route;
        this._declarationService = declarationService;
        this._userService = userService;
        this._translateService = translateService;
        this._router = router;
    }
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "declaration", {
        get: function () {
            return this._declaration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "protectionNumber", {
        get: function () {
            return this._protectionNumber;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "protection", {
        get: function () {
            return this._protection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "protectionUuid", {
        get: function () {
            return this._protectionUuid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "message", {
        get: function () {
            return this._message;
        },
        set: function (v) {
            this._message = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "messageBanner", {
        get: function () {
            return this._messageBanner;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "showForm", {
        get: function () {
            return this._showForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "messageStreamItems", {
        get: function () {
            return this._messageStreamItems;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "willRefund", {
        get: function () {
            return this._willRefund;
        },
        set: function (v) {
            this._willRefund = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "locale", {
        get: function () {
            return this._locale;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "textMask", {
        get: function () {
            return this._textMask;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "refundAmount", {
        get: function () {
            return this._refundAmount;
        },
        set: function (v) {
            this._refundAmount = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "currencySymbol", {
        get: function () {
            return this._currencySymbol;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "uploadDocuments", {
        get: function () {
            return this._uploadDocuments;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDetailsComponent.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    DeclarationsDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var css = '#shopSelectorShopDiv { display: none; }';
        var styleElement = document.createElement('style');
        styleElement.type = 'text/css';
        styleElement.appendChild(document.createTextNode(css));
        if (this.containerElement) {
            this.containerElement.nativeElement.appendChild(styleElement);
        }
        this._isLoading = true;
        this._route.params.subscribe(function (params) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b, _c, _d, messageBannerText_1, headline, text, messages, _e, translateString, _f;
            var _this = this;
            return __generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _g.trys.push([0, 15, , 16]);
                        this._protectionNumber = params.protectionNumber;
                        this._protectionUuid = params.protectionUuid;
                        _a = this;
                        return [4 /*yield*/, this._declarationService.getProtection(this._protectionUuid)];
                    case 1:
                        _a._protection = _g.sent();
                        this._locale = this._translateService.currentLang;
                        this._refundAmount = common_1.formatNumber(this._protection.orderAmount, this._locale, '1.2-2');
                        this._textMask = createNumberMask_1.default({
                            prefix: '',
                            decimalSymbol: common_1.getLocaleNumberSymbol(this._locale, common_1.NumberSymbol.CurrencyDecimal),
                            thousandsSeparatorSymbol: common_1.getLocaleNumberSymbol(this._locale, common_1.NumberSymbol.CurrencyGroup),
                            allowDecimal: true,
                            decimalLimit: 2
                        });
                        this._currencySymbol = common_1.getCurrencySymbol(this._protection.orderCurrency, 'narrow', this._locale);
                        if (!params.declarationUuid) return [3 /*break*/, 3];
                        _b = this;
                        return [4 /*yield*/, this._declarationService.getDeclaration(params.declarationUuid)];
                    case 2:
                        _b._declaration = _g.sent();
                        _g.label = 3;
                    case 3:
                        if (!(this._declaration && this._declaration.state !== declarationState_enum_1.DeclarationState.CANCELED)) return [3 /*break*/, 13];
                        if (!this._declaration.currency) {
                            this._declaration.currency = this._protection.orderCurrency;
                        }
                        if (!(!this._declaration.shopAssignee || this._declaration.shopAssignee !== this._userService.shop_uuid.value)) return [3 /*break*/, 5];
                        _c = this;
                        return [4 /*yield*/, this._declarationService.claimDeclaration(params.declarationUuid)];
                    case 4:
                        _c._declaration = _g.sent();
                        _g.label = 5;
                    case 5:
                        _d = this;
                        return [4 /*yield*/, this._declarationService.getDocuments(params.declarationUuid)];
                    case 6:
                        _d._documents = _g.sent();
                        messageBannerText_1 = 'DECLARATION_MESSAGE_BANNER.TS_CHECK_TEXT';
                        if (this._declaration.state === declarationState_enum_1.DeclarationState.TS_CHECK &&
                            this._declaration.refundReceived && this._declaration.refundReceived === 'NOT_RECEIVED') {
                            messageBannerText_1 = 'DECLARATION_MESSAGE_BANNER.TS_CHECK_CONSUMER_FEEDBACK_TEXT';
                        }
                        else if (this._declaration.state === declarationState_enum_1.DeclarationState.TS_CHECK &&
                            this._declaration.refundReceived && this._declaration.refundReceived === 'NOT_ACCEPTED') {
                            messageBannerText_1 = 'DECLARATION_MESSAGE_BANNER.TS_CHECK_CONSUMER_FEEDBACK_NOT_ACCEPTED_TEXT';
                        }
                        else if ((this._declaration.state === declarationState_enum_1.DeclarationState.TS_CHECK ||
                            this._declaration.state === declarationState_enum_1.DeclarationState.CONSUMER_CONFIRM) &&
                            !this._declaration.refundReceived &&
                            !this._declaration.feedbackShop &&
                            this._declaration.oldState !== 'SHOP_INFO_PROVIDED') {
                            messageBannerText_1 = 'DECLARATION_MESSAGE_BANNER.TS_CHECK_SHOP_DID_NOT_ANSWER_TEXT';
                        }
                        if (!(this._declaration.state === declarationState_enum_1.DeclarationState.TS_CHECK ||
                            this._declaration.state === declarationState_enum_1.DeclarationState.CONSUMER_CONFIRM ||
                            this._declaration.state === declarationState_enum_1.DeclarationState.FEEDBACK_NEEDED_CONSUMER ||
                            this._declaration.state === declarationState_enum_1.DeclarationState.WAITING_FOR_BANKACCOUNT_INFO)) return [3 /*break*/, 9];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('DECLARATION_MESSAGE_BANNER.TS_CHECK_HEADLINE').subscribe(function (next) {
                                    resolve(next);
                                });
                            })];
                    case 7:
                        headline = _g.sent();
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get(messageBannerText_1).subscribe(function (next) {
                                    resolve(next);
                                });
                            })];
                    case 8:
                        text = _g.sent();
                        this._messageBanner = {
                            headline: headline,
                            message: text,
                            symbol: 'fa-info-circle',
                            type: 'orange'
                        };
                        _g.label = 9;
                    case 9: return [4 /*yield*/, this._declarationService.getMessagesForDeclaration(params.declarationUuid)];
                    case 10:
                        messages = _g.sent();
                        return [4 /*yield*/, this._parseMessages(messages)];
                    case 11:
                        messages = _g.sent();
                        _e = this;
                        return [4 /*yield*/, this._buildMessageStream(messages)];
                    case 12:
                        _e._messageStreamItems = _g.sent();
                        this._uploadDocuments = this._documents.filter(function (x) { return x.status === 'UNCOMMITTED' &&
                            (!x.attachedToMessages || x.attachedToMessages.length === 0); }).map(function (document) {
                            return {
                                document: document
                            };
                        });
                        this._isLoading = false;
                        return [3 /*break*/, 14];
                    case 13:
                        translateString = 'MESSAGE_STREAM.ACTIVE_PROTECTION';
                        if (this._protection.protectionState === 'CLOSED' || new Date(this._protection.expirationDate).getTime() < new Date().getTime()
                            || this._declaration && this.declaration.state === 'CANCELED') {
                            translateString = 'MESSAGE_STREAM.PROTECTION_CLOSED';
                        }
                        this._translateService.get(translateString, { date: common_1.formatDate(new Date(this._protection.expirationDate).getTime(), 'shortDate', this._locale) })
                            .subscribe(function (next) {
                            _this._messageStreamItems = [{
                                    message: next,
                                    translateMessage: false,
                                    sender: 'NONE',
                                    initiallyOpen: true,
                                    showBorder: false
                                }];
                            _this._isLoading = false;
                        });
                        _g.label = 14;
                    case 14: return [3 /*break*/, 16];
                    case 15:
                        _f = _g.sent();
                        return [3 /*break*/, 16];
                    case 16: return [2 /*return*/];
                }
            });
        }); });
    };
    DeclarationsDetailsComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var saved, savedMessage, documentUuids, feedback, amountRefund, e_1, commited, _a, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this._isLoading = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 8, , 9]);
                        return [4 /*yield*/, this._declarationService.createMessageForDeclaration(this._declaration.declarationUuid, this._message)];
                    case 2:
                        savedMessage = _b.sent();
                        if (!(this._uploadDocuments.length > 0)) return [3 /*break*/, 4];
                        documentUuids = this._uploadDocuments.map(function (x) { return x.document.uuid; });
                        return [4 /*yield*/, this._declarationService.attachDocumentToMessage(this._declaration.declarationUuid, savedMessage.uuid, documentUuids)];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4:
                        if (!(this._declaration.state === declarationState_enum_1.DeclarationState.DECLARED)) return [3 /*break*/, 6];
                        feedback = declarationShopFeedback_enum_1.DeclarationShopFeedback.NON_REFUND;
                        sessionStorage.setItem('message_type', JSON.stringify({ type: 'SAVED', protectionNumber: this._protectionNumber }));
                        amountRefund = 0.0;
                        if (this.willRefund === 1) {
                            if (this._parseFloat(this._refundAmount) < this._protection.orderAmount) {
                                feedback = declarationShopFeedback_enum_1.DeclarationShopFeedback.PARTIAL_REFUND;
                                amountRefund = this._parseFloat(this._refundAmount);
                            }
                            else {
                                feedback = declarationShopFeedback_enum_1.DeclarationShopFeedback.REFUND;
                                amountRefund = this._protection.orderAmount;
                            }
                            sessionStorage.setItem('message_type', JSON.stringify({ type: 'SAVED', protectionNumber: this._protectionNumber }));
                        }
                        else if (this.willRefund === 2) {
                            if (this._parseFloat(this._refundAmount) < this._protection.orderAmount) {
                                feedback = declarationShopFeedback_enum_1.DeclarationShopFeedback.PARTIAL_REFUND_LATER;
                                amountRefund = this._parseFloat(this._refundAmount);
                            }
                            else {
                                feedback = declarationShopFeedback_enum_1.DeclarationShopFeedback.REFUND_LATER;
                                amountRefund = this._protection.orderAmount;
                            }
                            sessionStorage.setItem('message_type', JSON.stringify({ type: 'SAVED', protectionNumber: this._protectionNumber }));
                        }
                        return [4 /*yield*/, this._declarationService.updateDeclaration(this._declaration.declarationUuid, feedback, amountRefund)];
                    case 5:
                        saved = _b.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        saved = true;
                        _b.label = 7;
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        e_1 = _b.sent();
                        this.errorModal.openModal();
                        saved = false;
                        this._isLoading = false;
                        return [3 /*break*/, 9];
                    case 9:
                        if (!saved) return [3 /*break*/, 17];
                        commited = void 0;
                        _b.label = 10;
                    case 10:
                        _b.trys.push([10, 15, , 16]);
                        if (!(this._declaration.state === declarationState_enum_1.DeclarationState.DECLARED)) return [3 /*break*/, 12];
                        return [4 /*yield*/, this._declarationService.commitFeedback(this._declaration.declarationUuid)];
                    case 11:
                        _a = _b.sent();
                        return [3 /*break*/, 14];
                    case 12: return [4 /*yield*/, this._declarationService.commitAdditionalInformation(this._declaration.declarationUuid)];
                    case 13:
                        _a = _b.sent();
                        _b.label = 14;
                    case 14:
                        commited = _a;
                        return [3 /*break*/, 16];
                    case 15:
                        e_2 = _b.sent();
                        this.errorModal.openModal();
                        commited = false;
                        this._isLoading = false;
                        return [3 /*break*/, 16];
                    case 16:
                        if (commited) {
                            this._isLoading = false;
                            this._router.navigate(['']);
                        }
                        _b.label = 17;
                    case 17: return [2 /*return*/];
                }
            });
        });
    };
    DeclarationsDetailsComponent.prototype.documentsChanged = function (documents) {
        this._uploadDocuments = documents;
    };
    DeclarationsDetailsComponent.prototype.triggerFormOpen = function () {
        this._showForm = 'show';
    };
    DeclarationsDetailsComponent.prototype._parseFloat = function (value) {
        var decimal = common_1.getLocaleNumberSymbol(this._translateService.currentLang, common_1.NumberSymbol.CurrencyDecimal);
        var group = common_1.getLocaleNumberSymbol(this._translateService.currentLang, common_1.NumberSymbol.CurrencyGroup);
        var parsed = value.replace(group, '').replace(decimal, '.');
        return parseFloat(parsed);
    };
    DeclarationsDetailsComponent.prototype._parseMessages = function (messages) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(messages.map(function (message) { return __awaiter(_this, void 0, void 0, function () {
                            var translations, translationKeys_1, translated;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (message.message === null || message.message === undefined) {
                                            message.message = '';
                                        }
                                        translations = message.message.match(/__translate__[A-Z._]+/gm);
                                        if (!translations) return [3 /*break*/, 2];
                                        translationKeys_1 = translations.map(function (key) { return key.substr(13); });
                                        return [4 /*yield*/, new Promise(function (resolve) {
                                                _this._translateService.get(translationKeys_1).subscribe(function (values) {
                                                    resolve(translationKeys_1.map(function (key) { return values[key]; }));
                                                });
                                            })];
                                    case 1:
                                        translated = _a.sent();
                                        translated.forEach(function (key, index) {
                                            message.message = message.message.replace(translations[index], key);
                                        });
                                        _a.label = 2;
                                    case 2: return [2 /*return*/, message];
                                }
                            });
                        }); }))];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DeclarationsDetailsComponent.prototype._buildMessageStream = function (messages) {
        return __awaiter(this, void 0, void 0, function () {
            var messageItems, headline, _i, messages_1, declarationMessage, messageHeadline, locale, formattedAmount, currencySymbol, refundAmount_1, locale, formattedAmount, currencySymbol, refundAmount_2, headline, headline, headline, headline, message, locale, formattedAmount, currencySymbol, refundAmount_3, headline, message, locale, formattedAmount, currencySymbol, refundAmount_4, locale, formattedAmount, currencySymbol, refundAmount_5;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        messageItems = new Array();
                        if (!(messages.length === 0 && (this._declaration.state === declarationState_enum_1.DeclarationState.CLOSED || this._declaration.state === 'CANCELED'))) return [3 /*break*/, 2];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 1:
                        headline = _a.sent();
                        messageItems.push({
                            sender: 'NONE',
                            headline: headline,
                            initiallyOpen: true,
                            showBorder: false,
                            declarationUuid: this._declaration.declarationUuid
                        });
                        return [2 /*return*/, messageItems];
                    case 2:
                        if (this._declaration.migrated && messages[0].senderRole !== 'CONSUMER') {
                            messageItems.push({
                                sender: 'CONSUMER',
                                userEmail: this._protection.consumerProtectionEmail,
                                initiallyOpen: true,
                                showBorder: false,
                                declarationUuid: this._declaration.declarationUuid
                            });
                        }
                        for (_i = 0, messages_1 = messages; _i < messages_1.length; _i++) {
                            declarationMessage = messages_1[_i];
                            messageItems.push({
                                creationDate: new Date(declarationMessage.commitDate !== null ? declarationMessage.commitDate : declarationMessage.creationDate),
                                message: declarationMessage.message,
                                messageUuid: declarationMessage.uuid,
                                translateMessage: false,
                                sender: declarationMessage.senderRole,
                                initiallyOpen: false,
                                deliveryDate: this._declaration.actualDeliveryDate,
                                showBorder: true,
                                declarationUuid: this._declaration.declarationUuid,
                                userEmail: declarationMessage.senderRole === 'CONSUMER' ? this._protection.consumerProtectionEmail : null
                            });
                        }
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.NO_DELIVERY_HEADLINE').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 3:
                        messageHeadline = _a.sent();
                        if (!(this._declaration.reason === 'RNR')) return [3 /*break*/, 5];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.NO_REFUND_HEADLINE').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 4:
                        messageHeadline = _a.sent();
                        messageItems[0].revocationDate = this._declaration.cancellationDate;
                        _a.label = 5;
                    case 5:
                        messageItems[0].headline = messageHeadline;
                        if (!this._declaration.feedbackShop) return [3 /*break*/, 15];
                        if (this._declaration.migrated && (messages.length === 0 || messages[0].senderRole !== 'SHOP')) {
                            messageItems.splice(1, 0, {
                                sender: 'SHOP',
                                initiallyOpen: true,
                                showBorder: true,
                                declarationUuid: this._declaration.declarationUuid,
                            });
                        }
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.SHOP_WILL_REFUND').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 6:
                        messageHeadline = _a.sent();
                        if (!(this._declaration.feedbackShop === declarationShopFeedback_enum_1.DeclarationShopFeedback.NON_REFUND)) return [3 /*break*/, 8];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.SHOP_WONT_REFUND').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 7:
                        messageHeadline = _a.sent();
                        return [3 /*break*/, 14];
                    case 8:
                        if (!(this._declaration.feedbackShop === declarationShopFeedback_enum_1.DeclarationShopFeedback.REFUND_LATER)) return [3 /*break*/, 10];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.SHOP_WILL_REFUND_LATER').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 9:
                        messageHeadline = _a.sent();
                        return [3 /*break*/, 14];
                    case 10:
                        if (!(this._declaration.feedbackShop === declarationShopFeedback_enum_1.DeclarationShopFeedback.PARTIAL_REFUND_LATER)) return [3 /*break*/, 12];
                        locale = this._translateService.currentLang;
                        formattedAmount = common_1.formatNumber(this._declaration.amountRefund, locale, '1.2-2');
                        currencySymbol = common_1.getCurrencySymbol(this._declaration.currency, 'narrow', locale);
                        refundAmount_1 = formattedAmount + " " + currencySymbol;
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.SHOP_WILL_PARTIALLY_REFUND_LATER', { refund: refundAmount_1 }).subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 11:
                        messageHeadline = _a.sent();
                        return [3 /*break*/, 14];
                    case 12:
                        if (!(this._declaration.feedbackShop === declarationShopFeedback_enum_1.DeclarationShopFeedback.PARTIAL_REFUND)) return [3 /*break*/, 14];
                        locale = this._translateService.currentLang;
                        formattedAmount = common_1.formatNumber(this._declaration.amountRefund, locale, '1.2-2');
                        currencySymbol = common_1.getCurrencySymbol(this._declaration.currency, 'narrow', locale);
                        refundAmount_2 = formattedAmount + " " + currencySymbol;
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.SHOP_WILL_PARTIALLY_REFUND', { refund: refundAmount_2 }).subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 13:
                        messageHeadline = _a.sent();
                        _a.label = 14;
                    case 14:
                        messageItems[1].headline = messageHeadline;
                        return [3 /*break*/, 17];
                    case 15:
                        if (!(!this._declaration.feedbackShop &&
                            this._declaration.oldState !== 'SHOP_INFO_PROVIDED' &&
                            this._declaration.state !== declarationState_enum_1.DeclarationState.DECLARED &&
                            messages.filter(function (m) { return m.senderRole === 'EMPLOYEE'; }).length > 0)) return [3 /*break*/, 17];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.SHOP_DID_NOT_ANSWER').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 16:
                        headline = _a.sent();
                        messageItems.splice(1, 0, {
                            commitCycle: this._declaration.commitCycle,
                            message: 'MESSAGE_STREAM.SEND_TO_TS_FOR_CHECK',
                            translateMessage: true,
                            sender: 'EMPLOYEE',
                            headline: headline,
                            initiallyOpen: messages.length === 0 && !this._declaration.decision,
                            showBorder: true,
                            userEmail: this._protection.consumerProtectionEmail,
                            declarationUuid: this._declaration.declarationUuid
                        });
                        _a.label = 17;
                    case 17:
                        if (!(this._declaration.refundReceived && this._declaration.refundReceived === 'NOT_ACCEPTED')) return [3 /*break*/, 19];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.NOT_ACCEPTED').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 18:
                        headline = _a.sent();
                        messageItems[2].headline = headline;
                        return [3 /*break*/, 21];
                    case 19:
                        if (!(this._declaration.refundReceived && this._declaration.refundReceived === 'NOT_RECEIVED')) return [3 /*break*/, 21];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.REFUND_NOT_RECEIVED').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 20:
                        headline = _a.sent();
                        messageItems.splice(2, 0, {
                            creationDate: new Date(this._declaration.changedOn),
                            commitCycle: this._declaration.commitCycle,
                            message: '',
                            translateMessage: false,
                            sender: 'CONSUMER',
                            headline: headline,
                            initiallyOpen: true,
                            showBorder: true,
                            userEmail: this._protection.consumerProtectionEmail,
                            declarationUuid: this._declaration.declarationUuid
                        });
                        _a.label = 21;
                    case 21:
                        if (!(this._declaration.state === declarationState_enum_1.DeclarationState.CLOSED && !this._declaration.decision)) return [3 /*break*/, 32];
                        headline = void 0;
                        message = void 0;
                        if (!this._declaration.refundReceived) return [3 /*break*/, 25];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 22:
                        headline = _a.sent();
                        if (!(this._declaration.refundReceived === 'RECEIVED')) return [3 /*break*/, 24];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.RECEIVED').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 23:
                        message = _a.sent();
                        _a.label = 24;
                    case 24: return [3 /*break*/, 30];
                    case 25: return [4 /*yield*/, new Promise(function (resolve) {
                            _this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED_AUTOMATICALLY').subscribe(function (translation) {
                                resolve(translation);
                            });
                        })];
                    case 26:
                        headline = _a.sent();
                        if (!(this._declaration.feedbackShop === 'REFUND')) return [3 /*break*/, 28];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED_NO_ANSWER').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 27:
                        message = _a.sent();
                        return [3 /*break*/, 30];
                    case 28:
                        locale = this._translateService.currentLang;
                        formattedAmount = common_1.formatNumber(this._declaration.amountRefund, locale, '1.2-2');
                        currencySymbol = common_1.getCurrencySymbol(this._declaration.currency, 'narrow', locale);
                        refundAmount_3 = formattedAmount + " " + currencySymbol;
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED_NO_ANSWER_PARTIAL', { refundAmount: refundAmount_3 }).subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 29:
                        message = _a.sent();
                        _a.label = 30;
                    case 30: return [4 /*yield*/, new Promise(function (resolve) {
                            _this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED').subscribe(function (translation) {
                                resolve(translation);
                            });
                        })];
                    case 31:
                        headline = _a.sent();
                        messageItems.push({
                            creationDate: new Date(this._declaration.changedOn),
                            commitCycle: this._declaration.commitCycle,
                            sender: this._declaration.refundReceived ? 'CONSUMER' : 'EMPLOYEE',
                            headline: headline,
                            initiallyOpen: true,
                            showBorder: true,
                            userEmail: this._protection.consumerProtectionEmail,
                            declarationUuid: this._declaration.declarationUuid
                        });
                        return [3 /*break*/, 40];
                    case 32:
                        if (!((this._declaration.state === declarationState_enum_1.DeclarationState.CLOSED || this._declaration.state === declarationState_enum_1.DeclarationState.DECISION_MADE) &&
                            this._declaration.decision)) return [3 /*break*/, 40];
                        headline = void 0;
                        message = this._declaration.decision.reason;
                        if (!(!this._declaration.decision.reasonable && !this._declaration.decision.goodwill)) return [3 /*break*/, 34];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.TS_DECISION_NO_REFUND').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 33:
                        /* ***************************************** */
                        /* Protection closed with decision no refund */
                        /* ***************************************** */
                        headline = _a.sent();
                        return [3 /*break*/, 38];
                    case 34:
                        if (!(this._declaration.decision.reasonable && !this._declaration.decision.goodwill)) return [3 /*break*/, 36];
                        locale = this._translateService.currentLang;
                        formattedAmount = common_1.formatNumber(this._declaration.decision.amountRefund, locale, '1.2-2');
                        currencySymbol = common_1.getCurrencySymbol(this._declaration.currency, 'narrow', locale);
                        refundAmount_4 = formattedAmount + " " + currencySymbol;
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.TS_DECISION_REFUND', { amount: refundAmount_4 }).subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 35:
                        headline = _a.sent();
                        return [3 /*break*/, 38];
                    case 36:
                        if (!(!this._declaration.decision.reasonable && this._declaration.decision.goodwill)) return [3 /*break*/, 38];
                        locale = this._translateService.currentLang;
                        formattedAmount = common_1.formatNumber(this._declaration.decision.amountRefund, locale, '1.2-2');
                        currencySymbol = common_1.getCurrencySymbol(this._declaration.currency, 'narrow', locale);
                        refundAmount_5 = formattedAmount + " " + currencySymbol;
                        message = '';
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.TS_DECISION_NO_REFUND', { amount: refundAmount_5 }).subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 37:
                        headline = _a.sent();
                        _a.label = 38;
                    case 38:
                        messageItems.push({
                            creationDate: new Date(this._declaration.changedOn),
                            headline: headline,
                            message: message,
                            translateMessage: false,
                            sender: 'EMPLOYEE',
                            initiallyOpen: true,
                            showBorder: true,
                            declarationUuid: this._declaration.declarationUuid
                        });
                        if (!(this._declaration.state === declarationState_enum_1.DeclarationState.CLOSED)) return [3 /*break*/, 40];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this._translateService.get('MESSAGE_STREAM.PROTECTION_CLOSED').subscribe(function (translation) {
                                    resolve(translation);
                                });
                            })];
                    case 39:
                        headline = _a.sent();
                        messageItems.push({
                            creationDate: new Date(this._declaration.changedOn),
                            commitCycle: this._declaration.commitCycle,
                            sender: this._declaration.refundReceived ? 'CONSUMER' : 'EMPLOYEE',
                            headline: headline,
                            initiallyOpen: true,
                            showBorder: true,
                            userEmail: this._protection.consumerProtectionEmail,
                            declarationUuid: this._declaration.declarationUuid
                        });
                        _a.label = 40;
                    case 40:
                        if (this._declaration.state === declarationState_enum_1.DeclarationState.DECLARED ||
                            this._declaration.state === declarationState_enum_1.DeclarationState.FEEDBACK_NEEDED_SHOP ||
                            this._declaration.state === declarationState_enum_1.DeclarationState.FEEDBACK_NEEDED_CONSUMER_AND_SHOP) {
                            messageItems[messageItems.length - 1].showButton = true;
                        }
                        messageItems[0].showBorder = false;
                        messageItems[messageItems.length - 1].initiallyOpen = true;
                        return [2 /*return*/, this._addDocuments(messageItems.map(function (x) {
                                if (!x.headline) {
                                    x.initiallyOpen = true;
                                }
                                return x;
                            })).reverse()];
                }
            });
        });
    };
    DeclarationsDetailsComponent.prototype._addDocuments = function (messageItems) {
        var _loop_1 = function (message) {
            if (message.messageUuid) {
                var filteredDocuments = this_1._documents.filter(function (document) { return document.attachedToMessages.includes(message.messageUuid); });
                if (filteredDocuments.length > 0) {
                    message.documents = filteredDocuments.map(function (document) {
                        return { document: document };
                    });
                }
            }
        };
        var this_1 = this;
        for (var _i = 0, messageItems_1 = messageItems; _i < messageItems_1.length; _i++) {
            var message = messageItems_1[_i];
            _loop_1(message);
        }
        return messageItems;
    };
    return DeclarationsDetailsComponent;
}());
exports.DeclarationsDetailsComponent = DeclarationsDetailsComponent;
