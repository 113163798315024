"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../../environments/environment");
function initializer(keycloak) {
    return function () { return keycloak.init({ initArgs: {
            url: environment_1.environment.keycloak.host,
            realm: environment_1.environment.keycloak.realm,
            clientId: environment_1.environment.keycloak.clientId
        } }); };
}
exports.initializer = initializer;
