<div class="filter">
  <form (ngSubmit)="setFilters()" class="filter-form" #filterForm="ngForm">
    <div class="row">
      <div class="form-group col-9 col-sm-10 col-md-12 col-lg-6">
        <select (change)="dropDownChanged()" [(ngModel)]="protectionState" name="protectionStatus" class="form-control">
          <option value="NONE" disabled>{{ 'FILTER_FORM.PROTECTION_STATUS' | translate }}:</option>
          <option *ngFor="let selProtectionState of protectionStates" [value]="selProtectionState">{{ 'FILTER_FORM.' + selProtectionState | translate }}</option>
        </select>
      </div>
      <div class="form-group d-none d-lg-block col-lg-6">
        <div class="flex-wrapper">
          <div class="input-group">
            <input [(ngModel)]="filterModel.protectionNumber" name="protectionId" [placeholder]="'FILTER_FORM.PROTECTION_NUMBER' | translate" class="form-control form-control-sm">
            <button><span class="fa fa-search"></span></button>
          </div>
          <div class="input-group">
            <input [(ngModel)]="filterModel.email" name="email" [placeholder]="'FILTER_FORM.MAIL' | translate" class="form-control form-control-sm">
            <button><span class="fa fa-search"></span></button>
          </div>
        </div>
      </div>
      <div class="col-3 col-sm-2 button-holder d-md-none">
        <button type="button" class="btn btn-circle" [class.ab]="hideAll" (click)="formToggle()">
          <span *ngIf="hideAll" class="line"></span>
          <span *ngIf="hideAll" class="line"></span>
          <span *ngIf="hideAll" class="line"></span>
          <span *ngIf="!hideAll" class="fa fa-times"></span>
        </button>
        <!--<button type="button" class="btn" (click)="resetFilter()">Reset</button>-->
      </div>
    </div>
    <div class="row align-items-end justify-content-between bottom-row" [style.overflow]="bottomRowHideOverflow ? 'hidden': 'visible'" [@bottomRowHidden]="hideAll" (@bottomRowHidden.start)="animationStart()" (@bottomRowHidden.done)="animationDone()">
      <div class="form-group col-12 d-lg-none">
        <div class="flex-wrapper">
          <div class="input-group">
            <input [(ngModel)]="filterModel.protectionNumber" name="protectionId" [placeholder]="'FILTER_FORM.PROTECTION_NUMBER' | translate" class="form-control form-control-sm">
            <button><span class="fa fa-search"></span></button>
          </div>
          <div class="input-group">
            <input [(ngModel)]="filterModel.email" name="email" [placeholder]="'FILTER_FORM.MAIL' | translate" class="form-control form-control-sm">
            <button><span class="fa fa-search"></span></button>
          </div>
        </div>
      </div>
      <div class="form-group col-12 col-md-6">
        <div class="flex-wrapper">
          <div class="input-group">
            <input name="fromDate"
                   [matDatepicker]="dpValidFrom"
                   [placeholder]="'FILTER_FORM.FROM' | translate"
                   class="form-control form-control-sm date"
                   (dateInput)="setDate($event, 'validFrom')"
                   (dateChange)="setDate($event, 'validFrom')"
                   [(ngModel)]="fromCalendarModel">
            <mat-datepicker #dpValidFrom></mat-datepicker>
            <button type="button" (click)="dpValidFrom.open()">
              <span class="fa fa-calendar"></span>
            </button>
          </div>
          <div class="input-group">
            <input name="toDate"
                   [matDatepicker]="dpValidTo"
                   [placeholder]="'FILTER_FORM.TO' | translate"
                   class="form-control form-control-sm date"
                   (dateInput)="setDate($event, 'validTo')"
                   (dateChange)="setDate($event, 'validTo')"
                   [(ngModel)]="toCalendarModel">
            <mat-datepicker #dpValidTo></mat-datepicker>
            <button type="button" (click)="dpValidTo.open()">
              <span class="fa fa-calendar"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group col-12 col-md-6">
        <div class="input-group">
          <input [(ngModel)]="filterModel.orderNumber" name="shopOrderId" [placeholder]="'FILTER_FORM.SHOP_ORDER_ID' | translate" class="form-control form-control-sm">
          <button><span class="fa fa-search"></span></button>
        </div>
      </div>
    </div>
  </form>
</div>
