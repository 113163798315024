import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  //#region Properties
  @Input() currentPage: number;

  @Input() itemsPerPage: number;

  @Input() public set totalAmount(v: number) {
    this._totalPages = Math.ceil(v / this.itemsPerPage);
  }

  @Output() setPage = new EventEmitter<number>();

  private _totalPages: number;
  public get totalPages(): number {
    return this._totalPages;
  }
  //#endregion

  setActualPage(page: number) {
    this.setPage.emit(page);
  }
}
