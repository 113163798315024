<ul class="pagination-list" *ngIf="totalPages > 1">
  <li *ngIf="totalPages > 4 && currentPage !== 1" (click)="setActualPage(currentPage - 1)"><span class="link fa fa-angle-left"></span></li>
  <li *ngIf="currentPage !== 1" (click)="setActualPage(1)"><span class="link grey">1</span></li>
  <li *ngIf="totalPages > 4 && currentPage > 3"><span class="grey">...</span></li>
  <li *ngIf="totalPages === 4 && currentPage > 3" (click)="setActualPage(currentPage - 2)"><span class="link grey">{{ currentPage - 2 }}</span></li>
  <li *ngIf="currentPage > 2" (click)="setActualPage(currentPage - 1)"><span class="link grey">{{ currentPage - 1 }}</span></li>
  <li>{{ currentPage }}</li>
  <li *ngIf="currentPage < totalPages - 1" (click)="setActualPage(currentPage + 1)"><span class="link grey">{{ currentPage + 1 }}</span></li>
  <li *ngIf="totalPages > 4 && totalPages - currentPage > 2"><span class="grey">...</span></li>
  <li *ngIf="totalPages === 4 && totalPages - currentPage > 2" (click)="setActualPage(currentPage + 2)"><span class="link grey">{{ currentPage + 2 }}</span></li>
  <li *ngIf="currentPage !== totalPages" (click)="setActualPage(totalPages)"><span class="link grey">{{ totalPages }}</span></li>
  <li *ngIf="totalPages > 4 && currentPage !== totalPages" (click)="setActualPage(currentPage + 1)"><span class="link fa fa-angle-right"></span></li>
</ul>
