<div class="documents">
  <div *ngIf="documents" class="documents-list-wrapper">
    <app-declaration-document *ngFor="let doc of documents"
                              [disableDelete]="disableDelete"
                              [documentData]="doc"
                              [declarationUuid]="declarationUuid"
                              (deleteDocument)="onDeleteDocument($event)"></app-declaration-document>
  </div>
</div>
<button *ngIf="documents && documents.length  > 0 && !disableUpload" (click)="documentInput.click()" class="btn upload">{{ 'DOCUMENTS.CHOOSE_ADDITIONAL_FILES' | translate }}</button>
<div class="drop-area"
     *ngIf="documents.length  === 0 && !disableUpload"
     [ngClass]="{highlight: highlight}"
     (dragenter)="highlightDropBox()"
     (dragleave)="unHighlightDropBox()"
     (dragover)="dragOverHandler($event)"
     (drop)="dropFiles($event)">
  <img src="/ngassets/images/filedrop-background.png" srcset="/ngassets/images/filedrop-background.png 1x, /ngassets/images/filedrop-background@2x.png 2x" [alt]="'DOCUMENTS.DROP_FILES_HERE' | translate">
  <div  class="file-drop-text">
    {{ 'DOCUMENTS.FILE_DROP_TEXT' | translate }}
  </div>
  <button (click)="documentInput.click()" class="btn">{{ 'DOCUMENTS.CHOOSE_FILE' | translate }}</button>
</div>
<input type="file" name="documentInput" (change)="onFilesAdded()" #documentInput multiple>
