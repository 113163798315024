"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var common_1 = require("@angular/common");
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var rxjs_1 = require("rxjs");
var DynamicCurrencyPipe = /** @class */ (function () {
    //#endregion
    //#region Ctor
    function DynamicCurrencyPipe(translateService) {
        this._translateService = translateService;
        this._currencyPipe = new common_1.CurrencyPipe(this._translateService.currentLang);
    }
    //#endregion
    //#region Public Methods
    DynamicCurrencyPipe.prototype.transform = function (value, currencyCode, display, digitsInfo, locale) {
        var _this = this;
        var subject = new rxjs_1.ReplaySubject();
        subject.next(this.parse(value, currencyCode, display, digitsInfo, this._translateService.currentLang));
        this._translateService
            .onLangChange.pipe(operators_1.map(function (x) {
            return subject.next(_this.parse(value, currencyCode, display, digitsInfo, x.lang));
        }));
        return subject;
    };
    //#endregion
    //#region Private Methods
    DynamicCurrencyPipe.prototype.parse = function (value, currencyCode, display, digitsInfo, locale) {
        return this._currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
    };
    return DynamicCurrencyPipe;
}());
exports.DynamicCurrencyPipe = DynamicCurrencyPipe;
