import { AfterViewInit, Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { IMessageBanner } from './message-banner.interface';

@Component({
  selector: 'app-message-banner',
  templateUrl: './message-banner.component.html',
  styleUrls: ['./message-banner.component.scss'],
  animations: [
    trigger('showBanner', [
      state('show', style({
        height: '*',
        borderStyle: 'solid',
        marginTop: '16px'
      })),
      state('hide', style({
        height: 0,
        borderStyle: 'hidden',
        marginTop: 0
      })),
      transition('show => hide', animate('200ms ease-in'))
    ])
  ]
})
export class MessageBannerComponent implements AfterViewInit {

  //#region Properties
  @Input() messageBanner: IMessageBanner;

  private _showBanner = 'show';
  public get showBanner(): string {
    return this._showBanner;
  }
  //#endregion

  ngAfterViewInit() {
    if (this.messageBanner && this.messageBanner.fadeOutAfter) {
      setTimeout(() => {
        this._showBanner = 'hide';
      }, this.messageBanner.fadeOutAfter);
    }
  }

}
