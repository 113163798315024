import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'htmlEncode'
})
export class HtmlEncodePipe implements PipeTransform {
  transform(value: string, ...args: any[]) {
    const div = document.createElement('div');
    div.innerText = value;
    return div.innerHTML;
  }
}
