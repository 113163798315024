import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { DynamicDatePipe } from '../core/pipes/dynamic-date.pipe';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DynamicCurrencyPipe } from '../core/pipes/dynamic-currency.pipe';
import { RouterModule } from '@angular/router';
import { Nl2BrPipe } from '../core/pipes/nl2br.pipe';
import { MessageBannerComponent } from './message-banner/message-banner.component';
import { TextMaskModule } from 'angular2-text-mask';
import { FormattedNumberValidator } from '../core/validators/formatted-number.validator';
import { LoadingIndicatorComponent } from '../controls/loading-indicator/loading-indicator.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingOverlayComponent } from '../controls/loading-overlay/loading-overlay.component';
import { DateAdapter, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { EuropeDateAdapter } from './europeDateAdapter';
import { HtmlEncodePipe } from '../core/pipes/html-encode.pipe';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  declarations: [
    DynamicDatePipe,
    DynamicCurrencyPipe,
    Nl2BrPipe,
    HtmlEncodePipe,
    MessageBannerComponent,
    FormattedNumberValidator,
    LoadingIndicatorComponent,
    LoadingOverlayComponent
  ],
  exports: [
    CommonModule,
    BrowserModule,
    TranslateModule,
    HttpClientModule,

    MessageBannerComponent,

    DynamicDatePipe,
    FormsModule,
    BrowserAnimationsModule,
    DynamicCurrencyPipe,
    RouterModule,
    Nl2BrPipe,
    HtmlEncodePipe,
    TextMaskModule,
    FormattedNumberValidator,
    LoadingIndicatorComponent,
    ModalModule,
    LoadingOverlayComponent,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    {provide: DateAdapter, useClass: EuropeDateAdapter}
  ]
})
export class SharedModule { }
