"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var core_1 = require("@ngx-translate/core");
var app_init_1 = require("./core/utils/app-init");
var WebpackTranslateLoader = /** @class */ (function () {
    function WebpackTranslateLoader() {
    }
    WebpackTranslateLoader.prototype.getTranslation = function (lang) {
        return rxjs_1.from(Promise.resolve().then(function () { return require("../i18n/" + lang + ".json"); }));
    };
    return WebpackTranslateLoader;
}());
exports.WebpackTranslateLoader = WebpackTranslateLoader;
var MyB2BWebMissingTranslationHandler = /** @class */ (function () {
    function MyB2BWebMissingTranslationHandler() {
    }
    MyB2BWebMissingTranslationHandler.prototype.handle = function (params) {
        return 'Keine Übersetzung gefunden!';
    };
    return MyB2BWebMissingTranslationHandler;
}());
exports.MyB2BWebMissingTranslationHandler = MyB2BWebMissingTranslationHandler;
var de_1 = require("@angular/common/locales/de");
var de_2 = require("@angular/common/locales/extra/de");
var en_1 = require("@angular/common/locales/en");
var en_2 = require("@angular/common/locales/extra/en");
var es_1 = require("@angular/common/locales/es");
var es_2 = require("@angular/common/locales/extra/es");
var fr_1 = require("@angular/common/locales/fr");
var fr_2 = require("@angular/common/locales/extra/fr");
var it_1 = require("@angular/common/locales/it");
var it_2 = require("@angular/common/locales/extra/it");
var nl_1 = require("@angular/common/locales/nl");
var nl_2 = require("@angular/common/locales/extra/nl");
var pl_1 = require("@angular/common/locales/pl");
var pl_2 = require("@angular/common/locales/extra/pl");
var pt_1 = require("@angular/common/locales/pt");
var pt_2 = require("@angular/common/locales/extra/pt");
var common_1 = require("@angular/common");
var environment_1 = require("../environments/environment");
var Keycloak_export_1 = require("./core/utils/Keycloak.export");
common_1.registerLocaleData(de_1.default, 'de', de_2.default);
common_1.registerLocaleData(de_1.default, 'de-DE', de_2.default);
common_1.registerLocaleData(de_1.default, 'de-AT', de_2.default);
common_1.registerLocaleData(de_1.default, 'de-CH', de_2.default);
common_1.registerLocaleData(en_1.default, 'en', en_2.default);
common_1.registerLocaleData(en_1.default, 'en-US', en_2.default);
common_1.registerLocaleData(en_1.default, 'en-GB', en_2.default);
common_1.registerLocaleData(es_1.default, 'es', es_2.default);
common_1.registerLocaleData(es_1.default, 'es-ES', es_2.default);
common_1.registerLocaleData(fr_1.default, 'fr', fr_2.default);
common_1.registerLocaleData(fr_1.default, 'fr-FR', fr_2.default);
common_1.registerLocaleData(it_1.default, 'it', it_2.default);
common_1.registerLocaleData(it_1.default, 'it-IT', it_2.default);
common_1.registerLocaleData(nl_1.default, 'nl', nl_2.default);
common_1.registerLocaleData(nl_1.default, 'nl-NL', nl_2.default);
common_1.registerLocaleData(pl_1.default, 'pl', pl_2.default);
common_1.registerLocaleData(pl_1.default, 'pl-PL', pl_2.default);
common_1.registerLocaleData(pt_1.default, 'pt', pt_2.default);
common_1.registerLocaleData(pt_1.default, 'pt-PT', pt_2.default);
var ɵ0 = Keycloak_export_1.Globals.keycloak, ɵ1 = window, ɵ2 = app_init_1.initializer, ɵ3 = environment_1.environment.keycloak.protectedUrls;
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
exports.AppModule = AppModule;
