"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var keycloak_service_1 = require("../services/keycloak.service");
var rxjs_1 = require("rxjs");
var environment_1 = require("../../../environments/environment");
var operators_1 = require("rxjs/operators");
var KeycloakInterceptor = /** @class */ (function () {
    //#endregion
    function KeycloakInterceptor(keycloakService) {
        //#endregion
        //#region Properties
        this.protectedUrls = environment_1.environment.keycloak.protectedUrls;
        this._keycloakService = keycloakService;
    }
    KeycloakInterceptor.prototype.intercept = function (req, next) {
        if (!this.protectedUrls
            .map(function (x) { return x.test(req.url); })
            .some(function (x) { return x; })) {
            return next.handle(req);
        }
        var token = new rxjs_1.ReplaySubject();
        this._keycloakService.getToken()
            .then(function (res) {
            token.next(res);
        });
        return token.pipe(operators_1.map(function (tokenReceived) {
            return req.clone({ headers: req.headers.set('Authorization', "Bearer " + tokenReceived) });
        }), operators_1.flatMap(function (newReq) { return next.handle(newReq); }));
    };
    return KeycloakInterceptor;
}());
exports.KeycloakInterceptor = KeycloakInterceptor;
