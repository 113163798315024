<div class="attachment-button" [ngClass]="{'error': showError}">
  <div
    *ngIf="showImage"
    class="doc image-wrapper"
    (click)="showDocument()">
    <div class="icon fa fa-check-circle" aria-hidden="true"></div>
    <div class="text">
      <div [innerText]="stripFileName(documentData.document.fileName)" [title]="documentData.document.fileName"></div>
    </div>
  </div>
  <span *ngIf="showImage && documentData.document.status === 'UNCOMMITTED' && !isDeleting || showError && !disableDelete" class="fa fa-trash delete-button" (click)="deleteDoc($event)"></span>

  <div *ngIf="showLoading" class="doc loading-wrapper">
    <div class="icon"><loading-indicator></loading-indicator></div>
    <div class="text">{{ 'DOCUMENTS.LOADING_PREVIEW' | translate }}</div>
  </div>
  <div *ngIf="showError" class="doc error-wrapper">
    <div class="icon fa fa-exclamation-circle" aria-hidden="true"></div>
    <div class="text">
      <div [innerText]="stripFileName(documentData.document.fileName) + ':'"></div>
      <div [innerText]="'DOCUMENTS.' + documentData.error | translate "></div>
    </div>
  </div>
  <div *ngIf="showProgress" class="doc progress-wrapper">
    <div class="icon"><loading-indicator></loading-indicator></div>
    <div class="text">{{ 'DOCUMENTS.PROGRESS' | translate }}:</div>
    <div class="my-progress-bar">
      <div class="my-progress-bar-inner" [style.width]="percentage + '%'"></div>
    </div>
  </div>
</div>
<app-document-modal #documentModal [documentData]="documentData" [declarationUuid]="declarationUuid"></app-document-modal>
