"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./document-modal.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("@angular/common");
var i4 = require("ngx-bootstrap/modal");
var i5 = require("ngx-bootstrap/component-loader");
var i6 = require("./document-modal.component");
var i7 = require("../../../core/services/declaration.service");
var i8 = require("@angular/platform-browser");
var styles_DocumentModalComponent = [i0.styles];
var RenderType_DocumentModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentModalComponent, data: {} });
exports.RenderType_DocumentModalComponent = RenderType_DocumentModalComponent;
function View_DocumentModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "preview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previewUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "no-preview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "fa fa-file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "filename-badge"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "text only-convert-newlines"]], [[8, "innerText", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "download-cta"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-primary"]], [[8, "innerText", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadDocument() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.extension)); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("DOCUMENT_MODAL.NO_PREVIEW_POSSIBLE")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("DOCUMENT_MODAL.DOWNLOAD")); _ck(_v, 8, 0, currVal_2); }); }
function View_DocumentModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.UpperCasePipe, []), i1.ɵqud(402653184, 1, { documentModal: 0 }), (_l()(), i1.ɵeld(2, 16777216, null, null, 14, "div", [["bsModal", ""], ["class", "modal fade"], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 212992, [[1, 4], ["documentModal", 4]], 0, i4.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i5.ComponentLoaderFactory], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentModalComponent_1)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentModalComponent_2)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_1 = !_co.noInlinePreview; _ck(_v, 14, 0, currVal_1); var currVal_2 = _co.noInlinePreview; _ck(_v, 16, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileName; _ck(_v, 8, 0, currVal_0); }); }
exports.View_DocumentModalComponent_0 = View_DocumentModalComponent_0;
function View_DocumentModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-document-modal", [], null, null, null, View_DocumentModalComponent_0, RenderType_DocumentModalComponent)), i1.ɵdid(1, 49152, null, 0, i6.DocumentModalComponent, [i7.DeclarationService, i8.DomSanitizer, "window"], null, null)], null, null); }
exports.View_DocumentModalComponent_Host_0 = View_DocumentModalComponent_Host_0;
var DocumentModalComponentNgFactory = i1.ɵccf("app-document-modal", i6.DocumentModalComponent, View_DocumentModalComponent_Host_0, { documentData: "documentData", declarationUuid: "declarationUuid" }, {}, []);
exports.DocumentModalComponentNgFactory = DocumentModalComponentNgFactory;
