import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from 'environments/environment';

@Injectable()
export class UserService {
  //#region Properties
  private _firstName = new ReplaySubject<string>();
  public get firstName(): ReplaySubject<string> {
    return this._firstName;
  }

  private _lastName = new ReplaySubject<string>();
  public get lastName(): ReplaySubject<string> {
    return this._lastName;
  }

  private _locale = new ReplaySubject<string>();
  public get locale(): ReplaySubject<string> {
    return this._locale;
  }

  private _email = new ReplaySubject<string>();
  public get email(): ReplaySubject<string> {
    return this._email;
  }

  private _shop_uuid = new BehaviorSubject<string>('');
  public get shop_uuid(): BehaviorSubject<string> {
    return this._shop_uuid;
  }

  private _shopId = new BehaviorSubject<number>(environment.shopOAuth);
  public get shopId(): BehaviorSubject<number> {
    return this._shopId;
  }
  //#endregion
}
