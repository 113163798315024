"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./loading-overlay.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./loading-overlay.component");
var styles_LoadingOverlayComponent = [i0.styles];
var RenderType_LoadingOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingOverlayComponent, data: {} });
exports.RenderType_LoadingOverlayComponent = RenderType_LoadingOverlayComponent;
function View_LoadingOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "loading-overlay"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "loading-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
exports.View_LoadingOverlayComponent_0 = View_LoadingOverlayComponent_0;
function View_LoadingOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "loading-overlay", [], null, null, null, View_LoadingOverlayComponent_0, RenderType_LoadingOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i2.LoadingOverlayComponent, [], null, null)], null, null); }
exports.View_LoadingOverlayComponent_Host_0 = View_LoadingOverlayComponent_Host_0;
var LoadingOverlayComponentNgFactory = i1.ɵccf("loading-overlay", i2.LoadingOverlayComponent, View_LoadingOverlayComponent_Host_0, {}, {}, []);
exports.LoadingOverlayComponentNgFactory = LoadingOverlayComponentNgFactory;
