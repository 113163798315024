"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("../../../../environments/environment");
var fileTypes_1 = require("../../ui/configuration/fileTypes");
var BehaviorSubject_1 = require("rxjs/BehaviorSubject");
var user_service_1 = require("../../../core/services/user.service");
var DeclarationService = /** @class */ (function () {
    //#endregion
    function DeclarationService(http, userService) {
        this._http = http;
        this._userService = userService;
    }
    DeclarationService.prototype.getDeclaration = function (declarationUuid) {
        var _this = this;
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/";
        return new Promise(function (resolve, reject) {
            return _this._http.get("" + url + declarationUuid).subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    DeclarationService.prototype.claimDeclaration = function (declarationUuid) {
        var _this = this;
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/";
        return new Promise(function (resolve, reject) {
            return _this._http.post("" + url + declarationUuid + "/claim", '', {
                headers: new http_1.HttpHeaders().set('Content-Type', 'application/json')
            }).subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    DeclarationService.prototype.updateDeclaration = function (declarationUuid, feedbackShop, amountRefund) {
        var _this = this;
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/";
        var body = {
            amountRefund: "" + amountRefund,
            feedbackShop: feedbackShop
        };
        return new Promise(function (resolve, reject) {
            return _this._http.put("" + url + declarationUuid, JSON.stringify(body), {
                headers: new http_1.HttpHeaders().set('Content-Type', 'application/json')
            }).subscribe(function (res) {
                resolve(true);
            }, function (err) {
                reject(err);
            });
        });
    };
    DeclarationService.prototype.commitFeedback = function (declarationUuid) {
        var _this = this;
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/";
        return new Promise(function (resolve, reject) {
            return _this._http.post("" + url + declarationUuid + "/commit-feedback", '', {
                headers: new http_1.HttpHeaders().set('Content-Type', 'application/json')
            }).subscribe(function (res) {
                resolve(true);
            }, function (err) {
                reject(err);
            });
        });
    };
    DeclarationService.prototype.commitAdditionalInformation = function (declarationUuid) {
        var _this = this;
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/";
        return new Promise(function (resolve, reject) {
            return _this._http.post("" + url + declarationUuid + "/commit-additional-information", '', {
                headers: new http_1.HttpHeaders().set('Content-Type', 'application/json')
            }).subscribe(function (res) {
                resolve(true);
            }, function (err) {
                reject(err);
            });
        });
    };
    DeclarationService.prototype.getMessagesForDeclaration = function (declarationUuid) {
        var _this = this;
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/";
        return new Promise(function (resolve, reject) {
            return _this._http.get("" + url + declarationUuid + "/messages").subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    DeclarationService.prototype.createMessageForDeclaration = function (declarationUuid, message) {
        var _this = this;
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/";
        return new Promise(function (resolve, reject) {
            var body = {
                message: message
            };
            return _this._http.post("" + url + declarationUuid + "/messages", JSON.stringify(body), {
                headers: new http_1.HttpHeaders().set('Content-Type', 'application/json')
            }).subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    DeclarationService.prototype.updateMessageForDeclaration = function (declarationUuid, messageUuid, message) {
        var _this = this;
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/";
        return new Promise(function (resolve, reject) {
            var body = {
                message: message
            };
            return _this._http.put("" + url + declarationUuid + "/messages/" + messageUuid, JSON.stringify(body), {
                headers: new http_1.HttpHeaders().set('Content-Type', 'application/json')
            }).subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    DeclarationService.prototype.attachDocumentToMessage = function (declarationUuid, messageUuid, documentUuids) {
        var _this = this;
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/";
        return new Promise(function (resolve, reject) {
            var body = {
                documentUuids: documentUuids,
                action: 'UPLOAD'
            };
            return _this._http.patch("" + url + declarationUuid + "/messages/" + messageUuid, JSON.stringify(body), {
                headers: new http_1.HttpHeaders().set('Content-Type', 'application/json')
            }).subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    DeclarationService.prototype.getProtections = function (filters) {
        var _this = this;
        var url = environment_1.environment.protections.ordersApi + "uuid" + this._userService.shop_uuid.value + "/shop" + this._userService.shopId.value + "/protections";
        var params = new Array();
        if (filters) {
            for (var _i = 0, _a = Object.keys(filters); _i < _a.length; _i++) {
                var filter = _a[_i];
                params.push(filter + '=' + encodeURI(filters[filter]));
            }
            url = (url + "?" + params.join('&')).replace(/\+/g, '%2B');
        }
        return new Promise(function (resolve, reject) {
            return _this._http.get(url).subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    DeclarationService.prototype.getProtection = function (uuid) {
        var _this = this;
        var url = environment_1.environment.protections.ordersApi + "uuid" + this._userService.shop_uuid.value + "/shop" + this._userService.shopId.value + "/protections";
        return new Promise(function (resolve, reject) {
            return _this._http.get(url + "/" + uuid).subscribe(function (res) {
                resolve(res);
            }, function (err) {
                reject(err);
            });
        });
    };
    DeclarationService.prototype.upload = function (declarationUuid, files) {
        return __awaiter(this, void 0, void 0, function () {
            var url, status;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/" + declarationUuid + "/documents";
                        status = [];
                        return [4 /*yield*/, this._asyncForEach(files, function (file) { return __awaiter(_this, void 0, void 0, function () {
                                var data, formData, request, progress_1;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            data = {};
                                            if (!!fileTypes_1.fileTypes.includes(file.type)) return [3 /*break*/, 1];
                                            data.error = 'WRONG_TYPE';
                                            data.document = { fileName: file.name };
                                            return [3 /*break*/, 4];
                                        case 1:
                                            if (!(file.size > 10000000)) return [3 /*break*/, 2];
                                            data.error = 'FILE_SIZE';
                                            data.document = { fileName: file.name };
                                            return [3 /*break*/, 4];
                                        case 2:
                                            formData = new FormData();
                                            formData.append('file', file, file.name);
                                            request = new http_1.HttpRequest('POST', url, formData, { reportProgress: true });
                                            progress_1 = new BehaviorSubject_1.BehaviorSubject(0);
                                            this._http.request(request).subscribe(function (event) {
                                                if (event.type === http_1.HttpEventType.UploadProgress) {
                                                    var percent = Math.round(100 * event.loaded / event.total);
                                                    progress_1.next(percent);
                                                }
                                                else if (event instanceof http_1.HttpResponse) {
                                                    data.document = event.body;
                                                    progress_1.complete();
                                                }
                                            }, function (error) {
                                                if (error instanceof http_1.HttpErrorResponse
                                                    && error.error
                                                    && error.error.message.indexOf('FileSizeLimitExceededException') > -1) {
                                                    data.error = 'FILE_SIZE';
                                                }
                                                else if (error instanceof http_1.HttpErrorResponse && error.status === 400) {
                                                    data.error = 'WRONG_TYPE';
                                                }
                                                else {
                                                    data.error = 'GENERAL_ERROR';
                                                }
                                                data.document = { fileName: file.name };
                                                progress_1.complete();
                                            });
                                            data.progress = progress_1.asObservable();
                                            return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 100); })];
                                        case 3:
                                            _a.sent();
                                            _a.label = 4;
                                        case 4:
                                            status.push(data);
                                            return [2 /*return*/];
                                    }
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, status];
                }
            });
        });
    };
    DeclarationService.prototype.getDocuments = function (declarationUuid) {
        var _this = this;
        // tslint:disable-next-line:max-line-length
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/" + declarationUuid + "/documents";
        return new Promise((function (resolve, reject) {
            _this._http.get(url).subscribe(function (res) {
                resolve(res);
            }, function (error) {
                reject(error);
            });
        }));
    };
    DeclarationService.prototype.getDocument = function (declarationUuid, documentUuid) {
        var _this = this;
        // tslint:disable-next-line:max-line-length
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/" + declarationUuid + "/documents/" + documentUuid;
        return new Promise((function (resolve, reject) {
            _this._http.get(url).subscribe(function (res) {
                resolve(res);
            }, function (error) {
                reject(error);
            });
        }));
    };
    DeclarationService.prototype.deleteDocument = function (declarationUuid, documentUuid) {
        var _this = this;
        // tslint:disable-next-line:max-line-length
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/" + declarationUuid + "/documents/" + documentUuid;
        return new Promise((function (resolve, reject) {
            _this._http.delete(url).subscribe(function (res) {
                resolve(true);
            }, function (error) {
                reject(error);
            });
        }));
    };
    DeclarationService.prototype.getDocumentBlob = function (declarationUuid, documentUuid) {
        var _this = this;
        // tslint:disable-next-line:max-line-length
        var url = environment_1.environment.protections.declarationAPI + "uuid" + this._userService.shop_uuid.value + "/declarations/" + declarationUuid + "/documents/" + documentUuid + "/download";
        return new Promise(function (resolve, reject) {
            _this._http.get(url, { responseType: 'blob' })
                .subscribe(function (x) { return resolve(x); }, function (err) { return reject(err); });
        });
    };
    DeclarationService.prototype._asyncForEach = function (arr, callback) {
        return __awaiter(this, void 0, void 0, function () {
            var i;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < arr.length)) return [3 /*break*/, 4];
                        return [4 /*yield*/, callback(arr[i], i, arr)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return DeclarationService;
}());
exports.DeclarationService = DeclarationService;
