<div class="box" *ngIf="protection">
  <div class="row">
    <div class="col-12"><h3>{{ 'PROTECTION_INFOS.PROTECTION_DETAILS' | translate }}</h3></div>
  </div>
  <div class="row info-unit" [ngClass]="translate.currentLang">
    <div class="col-12 grey">{{ 'PROTECTION_INFOS.ORDER_DATE' | translate }}</div>
    <div class="col-12 long">{{ protection.orderDate | dynamicDate | async }}</div>
    <div class="col-12 grey">{{ 'PROTECTION_INFOS.ORDER_AMOUNT' | translate }}</div>
    <div class="col-12 long">{{ protection.orderAmount | dynamicCurrency:protection.orderCurrency:'symbol':'1.2-2' | async }}</div>
    <div class="col-12 grey">{{ 'PROTECTION_INFOS.SHOP_ORDER_ID' | translate }}</div>
    <div class="col-12 long" [title]="protection.orderNumber">{{ protection.orderNumber }}</div>
    <div class="col-12 grey">{{ 'PROTECTION_INFOS.CONTACT_EMAIL' | translate }}</div>
    <div class="col-12 long" [title]="protection.consumerContactEmail">{{ protection.consumerContactEmail }}</div>
    <div class="col-12 grey" *ngIf="protection.consumerContactEmail !== protection.consumerProtectionEmail">{{ 'PROTECTION_INFOS.ORDER_EMAIL' | translate }}</div>
    <div class="col-12 long" [title]="protection.consumerProtectionEmail" *ngIf="protection.consumerContactEmail !== protection.consumerProtectionEmail">{{ protection.consumerProtectionEmail }}</div>
  </div>
</div>
