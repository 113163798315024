import { Component, Inject, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { IDocumentDisplayData } from '../../../core/models/document-display-data.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DeclarationService } from '../../../core/services/declaration.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {
  //#region Properties
  @ViewChild(ModalDirective) documentModal: ModalDirective;
  //#endregion

  openModal() {
    this.documentModal.show();
  }
}
