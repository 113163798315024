import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable ,  ReplaySubject } from 'rxjs';

@Pipe({
  name: 'dynamicDate'
})
export class DynamicDatePipe implements PipeTransform {
  //#region Private Fields
  private readonly _translateService: TranslateService;
  private readonly _datePipe: DatePipe;
  //#endregion

  //#region Ctor
  public constructor(translateService: TranslateService) {
    this._translateService = translateService;
    this._datePipe = new DatePipe(this._translateService.currentLang);
  }
  //#endregion

  //#region Public Methods
  public transform(value: any, format?: string, timezone?: string, locale?: string): Observable<string> {
    const subject = new ReplaySubject<string>();
    subject.next(this.parse(value, format, timezone, this._translateService.currentLang));

    this._translateService
      .onLangChange
      .subscribe(x =>
        subject.next(this.parse(value, format, timezone, x.lang)));

    return subject;
  }
  //#endregion

  //#region Private Methods
  private parse(value: any, format?: string, timezone?: string, locale?: string): string {
    if (!format) {
      format = 'shortDate';
    }

    return this._datePipe.transform(value, format, timezone, locale);
  }

  //#endregion
}
