import { KeycloakService } from '../services/keycloak.service';
import { environment } from '../../../environments/environment';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => keycloak.init({initArgs: {
      url: environment.keycloak.host,
      realm: environment.keycloak.realm,
      clientId: environment.keycloak.clientId
    }});
}
