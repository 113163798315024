"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./loading-indicator.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./loading-indicator.component");
var styles_LoadingIndicatorComponent = [i0.styles];
var RenderType_LoadingIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingIndicatorComponent, data: {} });
exports.RenderType_LoadingIndicatorComponent = RenderType_LoadingIndicatorComponent;
function View_LoadingIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "loading-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "fa fa-refresh"]], null, null, null, null, null))], null, null); }
exports.View_LoadingIndicatorComponent_0 = View_LoadingIndicatorComponent_0;
function View_LoadingIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "loading-indicator", [], null, null, null, View_LoadingIndicatorComponent_0, RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i2.LoadingIndicatorComponent, [], null, null)], null, null); }
exports.View_LoadingIndicatorComponent_Host_0 = View_LoadingIndicatorComponent_Host_0;
var LoadingIndicatorComponentNgFactory = i1.ɵccf("loading-indicator", i2.LoadingIndicatorComponent, View_LoadingIndicatorComponent_Host_0, {}, {}, []);
exports.LoadingIndicatorComponentNgFactory = LoadingIndicatorComponentNgFactory;
