"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DeclarationState;
(function (DeclarationState) {
    DeclarationState["DECLARED"] = "DECLARED";
    DeclarationState["CLOSED"] = "CLOSED";
    DeclarationState["CANCELED"] = "CANCELED";
    DeclarationState["SHOP_ANSWERED"] = "SHOP_ANSWERED";
    DeclarationState["TS_CHECK"] = "TS_CHECK";
    DeclarationState["DECISION_MADE"] = "DECISION_MADE";
    DeclarationState["DECLARING"] = "DECLARING";
    DeclarationState["TS_CHECKED"] = "TS_CHECKED";
    DeclarationState["FEEDBACK_NEEDED_CONSUMER"] = "FEEDBACK_NEEDED_CONSUMER";
    DeclarationState["FEEDBACK_NEEDED_SHOP"] = "FEEDBACK_NEEDED_SHOP";
    DeclarationState["FEEDBACK_NEEDED_CONSUMER_AND_SHOP"] = "FEEDBACK_NEEDED_CONSUMER_AND_SHOP";
    DeclarationState["WAITING_FOR_BANKACCOUNT_INFO"] = "WAITING_FOR_BANKACCOUNT_INFO";
    DeclarationState["CONSUMER_CONFIRM"] = "CONSUMER_CONFIRM";
})(DeclarationState = exports.DeclarationState || (exports.DeclarationState = {}));
