"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var router_1 = require("@angular/router");
var user_service_1 = require("./core/services/user.service");
var AppComponent = /** @class */ (function () {
    //#endregion
    //#region Ctor
    function AppComponent(_translate, element, router, _userService, route, window) {
        var _this = this;
        this._translate = _translate;
        this.element = element;
        this.router = router;
        this._userService = _userService;
        this.route = route;
        //#region Properties
        this._myTsIntegrationActive = false;
        this._shopIsMigrating = false;
        this._queryParams = {};
        if (window.location.search) {
            var searchParamsKV = window.location.search.split('?')[1].split('&');
            searchParamsKV.forEach(function (param) {
                _this._queryParams[param.split('=')[0]] = param.split('=')[1];
            });
        }
    }
    Object.defineProperty(AppComponent.prototype, "myTsIntegrationActive", {
        get: function () {
            return this._myTsIntegrationActive;
        },
        set: function (v) {
            this._myTsIntegrationActive = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponent.prototype, "shopIsMigrating", {
        get: function () {
            return this._shopIsMigrating;
        },
        set: function (v) {
            this._shopIsMigrating = v;
        },
        enumerable: true,
        configurable: true
    });
    //#endregion
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        var componentElement = this.element.nativeElement;
        var currentLanguageAttribute = componentElement.attributes.getNamedItem('currentLanguage');
        if (currentLanguageAttribute !== null) {
            this._translate.setDefaultLang(currentLanguageAttribute.value);
            this._translate.use(currentLanguageAttribute.value);
        }
        else {
            this._translate.setDefaultLang('de');
            this._translate.use('de');
        }
        this.route.queryParams.subscribe(function (x) {
            if (x.shopId) {
                _this._userService.shopId.next(parseInt(x.shopId, 10));
            }
            if (x.declarationUuid && x.protectionUuid && x.protectionNumber) {
                setTimeout(function () { return _this.router.navigateByUrl("/declaration/" + x.protectionNumber + "/" + x.protectionUuid + "/" + x.declarationUuid); });
            }
        });
        if (Object.keys(this._queryParams).includes('shopId')) {
            this._userService.shopId.next(parseInt(this._queryParams['shopId'], 10));
        }
        if (Object.keys(this._queryParams).includes('selectedShopId')) {
            this._userService.shopId.next(parseInt(this._queryParams['selectedShopId'], 10));
        }
        if (Object.keys(this._queryParams).includes('declarationUuid')
            && Object.keys(this._queryParams).includes('protectionUuid')
            && Object.keys(this._queryParams).includes('protectionNumber')) {
            setTimeout(function () { return _this.router.navigateByUrl("/declaration/" + _this._queryParams['protectionNumber'] +
                ("/" + _this._queryParams['protectionUuid']) +
                ("/" + _this._queryParams['declarationUuid'])); });
        }
        var shopIdAttribute = componentElement.attributes.getNamedItem('shopid');
        if (shopIdAttribute !== null && shopIdAttribute.value && !isNaN(parseInt(shopIdAttribute.value, 10))) {
            this._userService.shopId.next(parseInt(shopIdAttribute.value, 10));
        }
        this.route.queryParams.subscribe(function (x) {
            if (x.selectedShopId && !isNaN(parseInt(x.selectedShopId, 10))) {
                _this._userService.shopId.next(parseInt(x.selectedShopId, 10));
            }
        });
        var myTsIntegrationAttribute = componentElement.attributes.getNamedItem('mytsintegrationactive');
        if (myTsIntegrationAttribute === null) {
            return;
        }
        if (myTsIntegrationAttribute.value.toLowerCase() === 'true') {
            this._myTsIntegrationActive = true;
        }
        var shopMigratingAttribute = componentElement.attributes.getNamedItem('shopmigrating');
        if (shopMigratingAttribute === null) {
            return;
        }
        if (shopMigratingAttribute.value.toLowerCase() === 'true') {
            this.shopIsMigrating = true;
        }
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
