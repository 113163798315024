import { NgModule } from '@angular/core';

import { DeclarationsRoutingModule } from './configuration/declarations-routing.module';
import { SearchDeclarationsComponent } from './views/search-protections/search-declarations.component';
import { SharedModule } from '../../shared/shared.module';
import { DeclarationsListComponent } from './views/declarations-list/declarations-list.component';
import { DeclarationsProtectionInfosComponent } from './views/declarations-protection-infos/declarations-protection-infos.component';
import { DeclarationsDetailsComponent } from './views/declarations-details/declarations-details.component';
import { PaginationComponent } from './views/pagination/pagination.component';
import { FilterFormComponent } from './views/filter-form/filter-form.component';
import { DeclarationMessageComponent } from './views/declaration-message/declaration-message.component';
import { DeclarationsDocumentComponent } from './views/declarations-document/declarations-document.component';
import { DeclarationsDocumentUploadComponent } from './views/declarations-document-upload/declarations-document-upload.component';
import { DocumentModalComponent } from './views/document-modal/document-modal.component';
import { ErrorModalComponent } from './views/error-modal/error-modal.component';

@NgModule({
  imports: [
    SharedModule,
    DeclarationsRoutingModule
  ],
  declarations: [
    SearchDeclarationsComponent,
    DeclarationsListComponent,
    DeclarationsProtectionInfosComponent,
    DeclarationsDetailsComponent,
    PaginationComponent,
    FilterFormComponent,
    DeclarationMessageComponent,
    DeclarationsDocumentComponent,
    DeclarationsDocumentUploadComponent,
    DocumentModalComponent,
    ErrorModalComponent
  ],
  entryComponents: [
    DeclarationsDetailsComponent
  ]
})
export class DeclarationsUiModule { }
