"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ngx_bootstrap_1 = require("ngx-bootstrap");
var ErrorModalComponent = /** @class */ (function () {
    function ErrorModalComponent() {
    }
    //#endregion
    ErrorModalComponent.prototype.openModal = function () {
        this.documentModal.show();
    };
    return ErrorModalComponent;
}());
exports.ErrorModalComponent = ErrorModalComponent;
