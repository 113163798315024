"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var BehaviorSubject_1 = require("rxjs/BehaviorSubject");
var environment_1 = require("environments/environment");
var UserService = /** @class */ (function () {
    function UserService() {
        //#region Properties
        this._firstName = new rxjs_1.ReplaySubject();
        this._lastName = new rxjs_1.ReplaySubject();
        this._locale = new rxjs_1.ReplaySubject();
        this._email = new rxjs_1.ReplaySubject();
        this._shop_uuid = new BehaviorSubject_1.BehaviorSubject('');
        this._shopId = new BehaviorSubject_1.BehaviorSubject(environment_1.environment.shopOAuth);
        //#endregion
    }
    Object.defineProperty(UserService.prototype, "firstName", {
        get: function () {
            return this._firstName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "lastName", {
        get: function () {
            return this._lastName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "locale", {
        get: function () {
            return this._locale;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "email", {
        get: function () {
            return this._email;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "shop_uuid", {
        get: function () {
            return this._shop_uuid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "shopId", {
        get: function () {
            return this._shopId;
        },
        enumerable: true,
        configurable: true
    });
    return UserService;
}());
exports.UserService = UserService;
