"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./message-banner.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./message-banner.component");
var styles_MessageBannerComponent = [i0.styles];
var RenderType_MessageBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageBannerComponent, data: { "animation": [{ type: 7, name: "showBanner", definitions: [{ type: 0, name: "show", styles: { type: 6, styles: { height: "*", borderStyle: "solid", marginTop: "16px" }, offset: null }, options: undefined }, { type: 0, name: "hide", styles: { type: 6, styles: { height: 0, borderStyle: "hidden", marginTop: 0 }, offset: null }, options: undefined }, { type: 1, expr: "show => hide", animation: { type: 4, styles: null, timings: "200ms ease-in" }, options: null }], options: {} }] } });
exports.RenderType_MessageBannerComponent = RenderType_MessageBannerComponent;
function View_MessageBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "wrapper"]], [[24, "@showBanner", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "row align-items-center"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-auto d-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "fa"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "col-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "row align-items-center"; var currVal_2 = _co.messageBanner.type; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = "fa"; var currVal_4 = _co.messageBanner.symbol; _ck(_v, 5, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showBanner; _ck(_v, 0, 0, currVal_0); var currVal_5 = _co.messageBanner.headline; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.messageBanner.message; _ck(_v, 10, 0, currVal_6); }); }
function View_MessageBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageBannerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messageBanner; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_MessageBannerComponent_0 = View_MessageBannerComponent_0;
function View_MessageBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-banner", [], null, null, null, View_MessageBannerComponent_0, RenderType_MessageBannerComponent)), i1.ɵdid(1, 4243456, null, 0, i3.MessageBannerComponent, [], null, null)], null, null); }
exports.View_MessageBannerComponent_Host_0 = View_MessageBannerComponent_Host_0;
var MessageBannerComponentNgFactory = i1.ɵccf("app-message-banner", i3.MessageBannerComponent, View_MessageBannerComponent_Host_0, { messageBanner: "messageBanner" }, {}, []);
exports.MessageBannerComponentNgFactory = MessageBannerComponentNgFactory;
