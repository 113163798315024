"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./error-modal.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("ngx-bootstrap/modal");
var i3 = require("ngx-bootstrap/component-loader");
var i4 = require("@ngx-translate/core");
var i5 = require("./error-modal.component");
var styles_ErrorModalComponent = [i0.styles];
var RenderType_ErrorModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorModalComponent, data: {} });
exports.RenderType_ErrorModalComponent = RenderType_ErrorModalComponent;
function View_ErrorModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { documentModal: 0 }), (_l()(), i1.ɵeld(1, 16777216, null, null, 13, "div", [["bsModal", ""], ["class", "modal fade"], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, [[1, 4], ["errorModal", 4]], 0, i2.ModalDirective, [i1.ElementRef, i1.ViewContainerRef, i1.Renderer2, i3.ComponentLoaderFactory], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h2", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("ERROR_MODAL.WE_ARE_SORRY")); _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("ERROR_MODAL.ERROR_REPEAT")); _ck(_v, 13, 0, currVal_1); }); }
exports.View_ErrorModalComponent_0 = View_ErrorModalComponent_0;
function View_ErrorModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-modal", [], null, null, null, View_ErrorModalComponent_0, RenderType_ErrorModalComponent)), i1.ɵdid(1, 49152, null, 0, i5.ErrorModalComponent, [], null, null)], null, null); }
exports.View_ErrorModalComponent_Host_0 = View_ErrorModalComponent_Host_0;
var ErrorModalComponentNgFactory = i1.ɵccf("app-error-modal", i5.ErrorModalComponent, View_ErrorModalComponent_Host_0, {}, {}, []);
exports.ErrorModalComponentNgFactory = ErrorModalComponentNgFactory;
