"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fileTypes = [
    'image/png',
    'image/jpeg',
    'application/pdf'
    // 'image/bmp',
    // 'image/gif',
    // 'image/tiff'
];
