import { Component, Input } from '@angular/core';
import {DeclarationService} from '../../../core/services/declaration.service';
import { IProtection } from '../../../core/models/protection.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-declarations-protection-infos',
  templateUrl: './declarations-protection-infos.component.html',
  styleUrls: ['./declarations-protection-infos.component.scss']
})
export class DeclarationsProtectionInfosComponent {
  //#region Properties
  private _protection: IProtection;
  @Input() public set protection(v: IProtection) {
    this._protection = v;
  }
  public get protection(): IProtection {
    return this._protection;
  }
  //#endregion

  public constructor(public translate: TranslateService) {}
}
