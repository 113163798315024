"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var declaration_service_1 = require("../../../core/services/declaration.service");
var rxjs_1 = require("rxjs");
require("rxjs/add/operator/map");
var DeclarationsDocumentUploadComponent = /** @class */ (function () {
    //#endregion
    //#region Ctor
    function DeclarationsDocumentUploadComponent(_declarationService) {
        this._declarationService = _declarationService;
        this._files = [];
        this._dragCounter = 0;
        this.documentsChanged = new core_1.EventEmitter();
        this._disableUpload = false;
        this._disableDelete = false;
        this._documents = [];
        this._highlight = false;
    }
    Object.defineProperty(DeclarationsDocumentUploadComponent.prototype, "disableUpload", {
        get: function () {
            return this._disableUpload;
        },
        set: function (v) {
            this._disableUpload = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDocumentUploadComponent.prototype, "disableDelete", {
        get: function () {
            return this._disableDelete;
        },
        set: function (v) {
            this._disableDelete = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDocumentUploadComponent.prototype, "documents", {
        get: function () {
            return this._documents;
        },
        set: function (v) {
            if (v === this._documents) {
                return;
            }
            this._documents = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDocumentUploadComponent.prototype, "declarationUuid", {
        get: function () {
            return this._declarationUuid;
        },
        set: function (v) {
            this._declarationUuid = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDocumentUploadComponent.prototype, "highlight", {
        get: function () {
            return this._highlight;
        },
        enumerable: true,
        configurable: true
    });
    //#endregion
    //#region Public Methods
    DeclarationsDocumentUploadComponent.prototype.writeValue = function (value) {
        if (value !== this._documents) {
            this._documents = value;
            this.documentsChanged.emit(null);
        }
    };
    DeclarationsDocumentUploadComponent.prototype.onFilesAdded = function () {
        this._upload(this._documentInput.nativeElement.files);
    };
    DeclarationsDocumentUploadComponent.prototype.dropFiles = function (e) {
        e.preventDefault();
        this._highlight = false;
        this._upload(e.dataTransfer.files);
    };
    DeclarationsDocumentUploadComponent.prototype.dragOverHandler = function (e) {
        e.preventDefault();
    };
    DeclarationsDocumentUploadComponent.prototype.highlightDropBox = function () {
        this._dragCounter++;
        this._highlight = true;
    };
    DeclarationsDocumentUploadComponent.prototype.unHighlightDropBox = function () {
        this._dragCounter--;
        if (this._dragCounter === 0) {
            this._highlight = false;
        }
    };
    DeclarationsDocumentUploadComponent.prototype._upload = function (files) {
        return __awaiter(this, void 0, void 0, function () {
            var key, documentsToAdd_1, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        for (key in files) {
                            if (!isNaN(parseInt(key, 10))) {
                                this._files.push(files[key]);
                            }
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this._declarationService.upload(this._declarationUuid, this._files)];
                    case 2:
                        documentsToAdd_1 = _a.sent();
                        this._documents = this._documents.concat(documentsToAdd_1);
                        this._files = [];
                        this.documentsChanged.emit(this._documents);
                        rxjs_1.forkJoin(documentsToAdd_1.map(function (x) { return x.progress; }))
                            .map(function (results) { return results.reduce(function (x, y) { return x + y; }); })
                            .subscribe(function (sumOfAllProgressValues) {
                            if (sumOfAllProgressValues === documentsToAdd_1.length * 100) {
                                _this._documentInput.nativeElement.value = '';
                            }
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DeclarationsDocumentUploadComponent.prototype.onDeleteDocument = function (documentViewModel) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, index;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = !documentViewModel.document.uuid;
                        if (_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._declarationService.deleteDocument(this._declarationUuid, documentViewModel.document.uuid)];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2:
                        if (_a) {
                            index = this._documents.findIndex(function (x) { return x === documentViewModel; });
                            if (index > -1) {
                                this._documents.splice(index, 1);
                                this.documentsChanged.emit(this._documents);
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return DeclarationsDocumentUploadComponent;
}());
exports.DeclarationsDocumentUploadComponent = DeclarationsDocumentUploadComponent;
