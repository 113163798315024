import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from './user.service';
import { ReplaySubject } from 'rxjs/index';

@Injectable()
export class KeycloakService {
  private readonly _keycloak: any;
  private readonly _window: any;
  private readonly _userService: UserService;
  private _auth: any = {};

  constructor(userService: UserService, @Inject('keycloak') keycloak: any, @Inject('window') window: any) {
    this._userService = userService;
    this._keycloak = keycloak;
    this._window = window;
  }

  init(args: any): Promise<any> {

    const keycloakAuth: any = this._keycloak(args.initArgs);

    return new Promise((resolve, reject) => {
      keycloakAuth.init({ onLoad: 'login-required', checkLoginIframe: false })
        .success(() => {
          this._auth = keycloakAuth;
          const helper = new JwtHelperService();
          const userObject = helper.decodeToken(this._auth.token);
          this._userService.firstName.next(userObject.given_name);
          this._userService.lastName.next(userObject.family_name);
          this._userService.locale.next(userObject.locale);
          this._userService.email.next(userObject.email);
          this._userService.shop_uuid.next(userObject['shopLoginUuid']);

          resolve();
        })
        .error(error => {
          reject(error);
        });
    });
  }

  logout(explicitLogoutUrl?: string) {
    // console.log('*** LOGOUT');
    this._auth.logout();
    this._auth = null;
    // window.location.href = this.cleanUrl(KeycloakService.auth.logoutUrl, explicitLogoutUrl);
  }

  getToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this._auth !== undefined && this._auth.token) {
        this._auth
          .updateToken(5)
          .success(() => {
            resolve(<string>this._auth.token);
          })
          .error(() => {
            this.logout();
            // this._window.location.reload();
            reject('Failed to refresh token');
          });
      } else {
        reject('Not logged in');
      }
    });
  }
}
