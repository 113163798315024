"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var declaration_service_1 = require("../../../core/services/declaration.service");
var document_modal_component_1 = require("../document-modal/document-modal.component");
var DeclarationsDocumentComponent = /** @class */ (function () {
    //#endregion
    function DeclarationsDocumentComponent(declarationService) {
        this.deleteDocument = new core_1.EventEmitter();
        this._showProgress = false;
        this._showLoading = false;
        this._showImage = false;
        this._showError = false;
        this._percentage = 0;
        this._isDeleting = false;
        this._declarationService = declarationService;
    }
    Object.defineProperty(DeclarationsDocumentComponent.prototype, "showProgress", {
        get: function () {
            return this._showProgress;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDocumentComponent.prototype, "showLoading", {
        get: function () {
            return this._showLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDocumentComponent.prototype, "showImage", {
        get: function () {
            return this._showImage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDocumentComponent.prototype, "showError", {
        get: function () {
            return this._showError;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDocumentComponent.prototype, "percentage", {
        get: function () {
            return this._percentage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDocumentComponent.prototype, "isDeleting", {
        get: function () {
            return this._isDeleting;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeclarationsDocumentComponent.prototype, "disableDelete", {
        get: function () {
            return this._disableDelete;
        },
        set: function (v) {
            this._disableDelete = v;
        },
        enumerable: true,
        configurable: true
    });
    DeclarationsDocumentComponent.prototype.stripFileName = function (name) {
        if (name.length > 12) {
            return name.substr(0, 7) + "..." + name.substr(name.length - 4);
        }
        else {
            return name;
        }
    };
    DeclarationsDocumentComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.documentData.error !== undefined) {
            this._showError = true;
        }
        else if (this.documentData.progress === undefined && this.documentData.document !== undefined) {
            this._showImage = true;
        }
        else if (this.documentData.progress !== undefined && this.documentData.document === undefined) {
            this._showProgress = true;
            var subscribe = this.documentData.progress.subscribe(function (next) {
                _this._percentage = next;
            }, function (error) {
                _this.documentData.error = 'GENERAL_ERROR';
                _this._showProgress = false;
                _this._showError = true;
            }, function () {
                _this._showProgress = false;
                if (_this.documentData.error !== undefined) {
                    _this._showError = true;
                }
                else {
                    _this._showImage = true;
                }
            });
        }
        else {
            this._showImage = true;
        }
    };
    DeclarationsDocumentComponent.prototype.deleteDoc = function (e) {
        var _this = this;
        e.stopPropagation();
        this._isDeleting = true;
        this.deleteDocument.emit(this.documentData);
        setTimeout(function () { return _this._isDeleting = false; }, 10000);
    };
    DeclarationsDocumentComponent.prototype.showDocument = function () {
        this.documentModal.openModal();
    };
    DeclarationsDocumentComponent.prototype._pollForThumbnail = function () {
        var _this = this;
        this._showLoading = true;
        var index = 0;
        var interval = setInterval(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.documentData.document.previewContentBase64.length === 0 && index < 10)) return [3 /*break*/, 5];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this.documentData;
                        return [4 /*yield*/, this._declarationService.getDocument(this.declarationUuid, this.documentData.document.uuid)];
                    case 2:
                        _a.document = _b.sent();
                        index++;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        index++;
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        if (this.documentData.document.previewContentBase64.length === 0 && index >= 10) {
                            clearInterval(interval);
                            this._showLoading = false;
                            this._showImage = true;
                        }
                        else {
                            clearInterval(interval);
                            this._showLoading = false;
                            this._showImage = true;
                        }
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); }, 1000);
    };
    return DeclarationsDocumentComponent;
}());
exports.DeclarationsDocumentComponent = DeclarationsDocumentComponent;
