"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var declarations_list_component_1 = require("../views/declarations-list/declarations-list.component");
var declarations_details_component_1 = require("../views/declarations-details/declarations-details.component");
var routes = [
    { path: '', component: declarations_list_component_1.DeclarationsListComponent },
    { path: 'declaration/:protectionNumber/:protectionUuid', component: declarations_details_component_1.DeclarationsDetailsComponent },
    { path: 'declaration/:protectionNumber/:protectionUuid/:declarationUuid', component: declarations_details_component_1.DeclarationsDetailsComponent }
];
var DeclarationsRoutingModule = /** @class */ (function () {
    function DeclarationsRoutingModule() {
    }
    return DeclarationsRoutingModule;
}());
exports.DeclarationsRoutingModule = DeclarationsRoutingModule;
