<div bsModal #errorModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title">{{ 'ERROR_MODAL.WE_ARE_SORRY' | translate }}</h2>
        <button type="button" class="close" aria-label="Close"  (click)="errorModal.hide()">
          <span class="fa fa-times" aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <span>{{ 'ERROR_MODAL.ERROR_REPEAT' | translate }}</span>
      </div>
    </div>
  </div>
</div>
