"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./declarations-document-upload.component.scss.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../declarations-document/declarations-document.component.ngfactory");
var i3 = require("../declarations-document/declarations-document.component");
var i4 = require("../../../core/services/declaration.service");
var i5 = require("@angular/common");
var i6 = require("@ngx-translate/core");
var i7 = require("./declarations-document-upload.component");
var styles_DeclarationsDocumentUploadComponent = [i0.styles];
var RenderType_DeclarationsDocumentUploadComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DeclarationsDocumentUploadComponent, data: {} });
exports.RenderType_DeclarationsDocumentUploadComponent = RenderType_DeclarationsDocumentUploadComponent;
function View_DeclarationsDocumentUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-declaration-document", [], null, [[null, "deleteDocument"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deleteDocument" === en)) {
        var pd_0 = (_co.onDeleteDocument($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DeclarationsDocumentComponent_0, i2.RenderType_DeclarationsDocumentComponent)), i1.ɵdid(1, 114688, null, 0, i3.DeclarationsDocumentComponent, [i4.DeclarationService], { documentData: [0, "documentData"], declarationUuid: [1, "declarationUuid"], disableDelete: [2, "disableDelete"] }, { deleteDocument: "deleteDocument" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.declarationUuid; var currVal_2 = _co.disableDelete; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DeclarationsDocumentUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "documents-list-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeclarationsDocumentUploadComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documents; _ck(_v, 2, 0, currVal_0); }, null); }
function View_DeclarationsDocumentUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn upload"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 8).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("DOCUMENTS.CHOOSE_ADDITIONAL_FILES")); _ck(_v, 1, 0, currVal_0); }); }
function View_DeclarationsDocumentUploadComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "drop-area"]], null, [[null, "dragenter"], [null, "dragleave"], [null, "dragover"], [null, "drop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragenter" === en)) {
        var pd_0 = (_co.highlightDropBox() !== false);
        ad = (pd_0 && ad);
    } if (("dragleave" === en)) {
        var pd_1 = (_co.unHighlightDropBox() !== false);
        ad = (pd_1 && ad);
    } if (("dragover" === en)) {
        var pd_2 = (_co.dragOverHandler($event) !== false);
        ad = (pd_2 && ad);
    } if (("drop" === en)) {
        var pd_3 = (_co.dropFiles($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { highlight: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "img", [["src", "/ngassets/images/filedrop-background.png"], ["srcset", "/ngassets/images/filedrop-background.png 1x, /ngassets/images/filedrop-background@2x.png 2x"]], [[8, "alt", 0]], null, null, null, null)), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "file-drop-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 8).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "drop-area"; var currVal_1 = _ck(_v, 2, 0, _co.highlight); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("DOCUMENTS.DROP_FILES_HERE")); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("DOCUMENTS.FILE_DROP_TEXT")); _ck(_v, 6, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("DOCUMENTS.CHOOSE_FILE")); _ck(_v, 9, 0, currVal_4); }); }
function View_DeclarationsDocumentUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { _documentInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "documents"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeclarationsDocumentUploadComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeclarationsDocumentUploadComponent_3)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeclarationsDocumentUploadComponent_4)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["documentInput", 1]], null, 0, "input", [["multiple", ""], ["name", "documentInput"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFilesAdded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documents; _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.documents && (_co.documents.length > 0)) && !_co.disableUpload); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((_co.documents.length === 0) && !_co.disableUpload); _ck(_v, 7, 0, currVal_2); }, null); }
exports.View_DeclarationsDocumentUploadComponent_0 = View_DeclarationsDocumentUploadComponent_0;
function View_DeclarationsDocumentUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-declaration-document-upload", [], null, null, null, View_DeclarationsDocumentUploadComponent_0, RenderType_DeclarationsDocumentUploadComponent)), i1.ɵdid(1, 49152, null, 0, i7.DeclarationsDocumentUploadComponent, [i4.DeclarationService], null, null)], null, null); }
exports.View_DeclarationsDocumentUploadComponent_Host_0 = View_DeclarationsDocumentUploadComponent_Host_0;
var DeclarationsDocumentUploadComponentNgFactory = i1.ɵccf("app-declaration-document-upload", i7.DeclarationsDocumentUploadComponent, View_DeclarationsDocumentUploadComponent_Host_0, { disableUpload: "disableUpload", disableDelete: "disableDelete", documents: "documents", declarationUuid: "declarationUuid" }, { documentsChanged: "documentsChanged" }, []);
exports.DeclarationsDocumentUploadComponentNgFactory = DeclarationsDocumentUploadComponentNgFactory;
