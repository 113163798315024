
import {from as observableFrom,  Observable } from 'rxjs';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { PageFrameModule } from './page-frame/page-frame.module';
import {DeclarationsModule} from './declarations/core/declarations.module';
import { initializer } from './core/utils/app-init';
import { KeycloakService } from './core/services/keycloak.service';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return observableFrom(import(`../i18n/${lang}.json`));
  }
}

export class MyB2BWebMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return 'Keine Übersetzung gefunden!';
  }
}

import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localePl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';
import localePt from '@angular/common/locales/pt';
import localePtExtra from '@angular/common/locales/extra/pt';
import {registerLocaleData} from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { KeycloakInterceptor } from './core/interceptors/keycloak.interceptor';
import { environment } from '../environments/environment';
import { UserService } from './core/services/user.service';
import { Globals } from './core/utils/Keycloak.export';
import { ModalModule } from 'ngx-bootstrap';

registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeDe, 'de-AT', localeDeExtra);
registerLocaleData(localeDe, 'de-CH', localeDeExtra);

registerLocaleData(localeEn, 'en', localeEnExtra);
registerLocaleData(localeEn, 'en-US', localeEnExtra);
registerLocaleData(localeEn, 'en-GB', localeEnExtra);

registerLocaleData(localeEs, 'es', localeEsExtra);
registerLocaleData(localeEs, 'es-ES', localeEsExtra);

registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

registerLocaleData(localeIt, 'it', localeItExtra);
registerLocaleData(localeIt, 'it-IT', localeItExtra);

registerLocaleData(localeNl, 'nl', localeNlExtra);
registerLocaleData(localeNl, 'nl-NL', localeNlExtra);

registerLocaleData(localePl, 'pl', localePlExtra);
registerLocaleData(localePl, 'pl-PL', localePlExtra);

registerLocaleData(localePt, 'pt', localePtExtra);
registerLocaleData(localePt, 'pt-PT', localePtExtra);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    DeclarationsModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyB2BWebMissingTranslationHandler
      }
    }),
    PageFrameModule,
    ModalModule.forRoot()
  ],
  providers: [
    { provide: 'keycloak', useValue: Globals.keycloak },
    { provide: 'window', useValue: window },
    UserService,
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakInterceptor,
      multi: true,
      deps: [KeycloakService]
    },
    { provide: 'protectedUrls', useValue: environment.keycloak.protectedUrls }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
