export enum DeclarationState {
  DECLARED = 'DECLARED',
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED',
  SHOP_ANSWERED = 'SHOP_ANSWERED',
  TS_CHECK = 'TS_CHECK',
  DECISION_MADE = 'DECISION_MADE',
  DECLARING = 'DECLARING',
  TS_CHECKED = 'TS_CHECKED',
  FEEDBACK_NEEDED_CONSUMER = 'FEEDBACK_NEEDED_CONSUMER',
  FEEDBACK_NEEDED_SHOP = 'FEEDBACK_NEEDED_SHOP',
  FEEDBACK_NEEDED_CONSUMER_AND_SHOP = 'FEEDBACK_NEEDED_CONSUMER_AND_SHOP',
  WAITING_FOR_BANKACCOUNT_INFO = 'WAITING_FOR_BANKACCOUNT_INFO',
  CONSUMER_CONFIRM = 'CONSUMER_CONFIRM'
}
