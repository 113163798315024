"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var MessageBannerComponent = /** @class */ (function () {
    function MessageBannerComponent() {
        this._showBanner = 'show';
    }
    Object.defineProperty(MessageBannerComponent.prototype, "showBanner", {
        get: function () {
            return this._showBanner;
        },
        enumerable: true,
        configurable: true
    });
    //#endregion
    MessageBannerComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.messageBanner && this.messageBanner.fadeOutAfter) {
            setTimeout(function () {
                _this._showBanner = 'hide';
            }, this.messageBanner.fadeOutAfter);
        }
    };
    return MessageBannerComponent;
}());
exports.MessageBannerComponent = MessageBannerComponent;
