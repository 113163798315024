"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./shared/message-banner/message-banner.component.ngfactory");
var i3 = require("./shared/message-banner/message-banner.component");
var i4 = require("@ngx-translate/core");
var i5 = require("@angular/router");
var i6 = require("@angular/common");
var i7 = require("./app.component");
var i8 = require("./core/services/user.service");
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-message-banner", [], null, null, null, i2.View_MessageBannerComponent_0, i2.RenderType_MessageBannerComponent)), i1.ɵdid(1, 4243456, null, 0, i3.MessageBannerComponent, [], { messageBanner: [0, "messageBanner"] }, null), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵpod(4, { headline: 0, message: 1, symbol: 2, type: 3 })], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("DECLARATION_MESSAGE_BANNER.PLEASE_TRY_AGAIN")), i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("DECLARATION_MESSAGE_BANNER.MIGRATION_IN_PROGRESS")), "fa-info-circle", "blue"); _ck(_v, 1, 0, currVal_0); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "main-content in-myts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shopIsMigrating; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.shopIsMigrating; _ck(_v, 5, 0, currVal_1); }, null); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i7.AppComponent, [i4.TranslateService, i1.ElementRef, i5.Router, i8.UserService, i5.ActivatedRoute, "window"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i1.ɵccf("app-root", i7.AppComponent, View_AppComponent_Host_0, { myTsIntegrationActive: "myTsIntegrationActive", shopIsMigrating: "shopIsMigrating" }, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
