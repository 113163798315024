<div class="wrapper" *ngIf="messageBanner" [@showBanner]="showBanner">
  <div class="row align-items-center" [ngClass]="messageBanner.type">
    <div class="col-auto d-none d-md-block">
      <span class="fa" [ngClass]="messageBanner.symbol"></span>
    </div>
    <div class="col-auto">
      <h6>
        {{ messageBanner.headline }}
      </h6>
      <p>
        {{ messageBanner.message }}
      </p>
    </div>
  </div>
</div>
