<div bsModal #documentModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ fileName }}</h4>
        <button type="button" class="close" aria-label="Close"  (click)="documentModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="preview" *ngIf="!noInlinePreview">
          <img [src]="previewUrl">
        </div>
        <div class="no-preview" *ngIf="noInlinePreview">
          <div class="icon">
            <span class="fa fa-file"></span>
            <span class="filename-badge" [innerText]="extension | uppercase"></span>
          </div>
          <div class="text only-convert-newlines" [innerText]="'DOCUMENT_MODAL.NO_PREVIEW_POSSIBLE' | translate"></div>
          <div class="download-cta">
            <button class="btn btn-primary" (click)="downloadDocument()" [innerText]="'DOCUMENT_MODAL.DOWNLOAD' | translate"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
